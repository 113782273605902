const _ = require('lodash');

import {
  getStaff,
  getNoOrder,
  getDiagnose,
  getLabResult,
  postLabResult,
  postLabRequest,
  getLabRequestById,
  deleteRegisterLab,
  getLabRegistration,
  getDiagnoseCheckup,
  postLabRegistration,
  putConfirmLabRequest,
  getLaboratoryServices,
} from '../../../fetchApi/laboratory';

const state = {
  activityStatusLab: '',
  showDialog: false,
  patientData: {},
  todayDate: new Date(),
  isChanged: false,
  isLoading: {
    allDiagnose: false,
    laboratoryServices: false,
    diagnoseCheckup: false,
    tableRegistration: false,
  },
  search: {
    searchQuery: '',
    searchResult: [],
  },
  inputOrder: {
    noOrder: '',
    checkedItems: [],
    provisionalDiagnosis: [''],
  },
  inputResult: {
    notes: '',
    recommendation: '',
    test_item: [],
    isChanged: true,
    staff: '',
    labResultTestItem: [],
  },
  snackbar: {
    show: false,
    color: 'success',
    text: '',
  },
  dataLab: {},

  // Api State
  laboratoryServices: [],
  diagnose: [],
  diagnoseCheckup: [],
  labReqById: {},
  labResultById: {},
  initialResultById: {},
  staff: [],
  labRegistration: [],
};

const initialState = {
  // showDialog: false,
  activityStatusLab: '',
  patientData: {},
  todayDate: new Date(),
  isChanged: false,
  isLoading: {
    allDiagnose: false,
    laboratoryServices: false,
    diagnoseCheckup: false,
    tableRegistration: false,
  },
  search: {
    searchQuery: '',
    searchResult: [],
  },
  inputOrder: {
    noOrder: '',
    checkedItems: [],
    provisionalDiagnosis: [''],
  },
  inputResult: {
    notes: '',
    recommendation: '',
    test_item: [],
    isChanged: true,
    staff: '',
    labResultTestItem: [],
  },
  dataLab: {},

  // Api State
  laboratoryServices: [],
  diagnose: [],
  diagnoseCheckup: [],
  labReqById: {},
  labResultById: {},
  initialResultById: {},
  staff: [],
  labRegistration: [],
};

const getters = {
  // Get activity status lab
  getActivityStatusLab: state => state.activityStatusLab,

  // Get show dialog
  getShowDialog: state => state.showDialog,

  // Get patient data
  getPatientData: state => state.patientData,

  // Get today date
  getTodayDate: state => state.todayDate,

  // Get is changed
  getIsChanged: state => state.isChanged,

  // Get search laboratory services
  getSearchQuery: state => state.search.searchQuery,
  getSearchResult: state => state.search.searchResult,

  // Get input order
  getInputOrderCheckedItems: state => state.inputOrder.checkedItems,
  getProvisionalDiagnosis: state => state.inputOrder.provisionalDiagnosis,
  getNoOrder: state => state.inputOrder.noOrder,

  // Get input result
  getInputResultNotes: state => state.inputResult.notes,
  getInputResultRecommendation: state => state.inputResult.recommendation,
  getInputResultIsChanged: state => state.inputResult.isChanged,
  getInputResultStaff: state => state.inputResult.staff,
  getInputResultTestItem: state => state.inputResult.labResultTestItem,

  // Get loading
  getIsLoading: state => key => state.isLoading[key],

  // Get snackbar
  getSnackbar: state => state.snackbar,

  // Get data lab
  getDataLab: state => state.dataLab,

  // Api
  // Get laboratory services
  getLaboratoryServices: state => state.laboratoryServices,
  getDiagnose: state => state.diagnose,
  getLabReqById: state => state.labReqById,
  getDiagnoseCheckup: state => state.diagnoseCheckup,
  getLabResultById: state => state.labResultById,
  getInitialResultById: state => state.initialResultById,
  getStaff: state => state.staff,
  getLabRegistration: state => state.labRegistration,
};

const mutations = {
  // Set activity status lab
  setActivityStatusLab(state, payload) {
    state.activityStatusLab = payload;
  },

  // Set show dialog
  setShowDialog(state, payload) {
    state.showDialog = payload;
  },

  // Clear state
  clearState(state) {
    Object.assign(state, initialState);
    state.inputOrder = {
      noOrder: '',
      checkedItems: [],
      provisionalDiagnosis: [''],
    };
  },

  clearSnackbar(state) {
    state.snackbar = {
      show: false,
      color: 'success',
      text: '',
    };
  },

  // Set patient data
  setPatientData(state, payload) {
    state.patientData = payload;
  },

  // Set isChanged
  setIsChanged(state, payload) {
    state.isChanged = payload;
  },

  // Set search
  setSearchQuery(state, payload) {
    state.search.searchQuery = payload;
  },
  setSearchResult(state, payload) {
    state.search.searchResult = payload;
  },

  // Set input order
  setInputOrderCheckedItems(state, payload) {
    state.inputOrder.checkedItems = payload;
  },
  setProvisionalDiagnosis(state, payload) {
    state.inputOrder.provisionalDiagnosis = payload;
  },
  setNoOrder(state, payload) {
    state.inputOrder.noOrder = payload;
  },

  // Set input result
  setInputResultNotes(state, payload) {
    state.inputResult.notes = payload;
  },
  setInputResultRecommendation(state, payload) {
    state.inputResult.recommendation = payload;
  },
  setInputResultIsChanged(state, payload) {
    state.inputResult.isChanged = payload;
  },
  setInputResultStaff(state, payload) {
    state.inputResult.staff = payload;
  },
  setInputResultTestItem(state, payload) {
    state.inputResult.labResultTestItem = payload;
  },

  // set loading
  setIsLoading(state, payload) {
    state.isLoading[payload.key] = payload.value;
  },

  // Set snackbar
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },

  // Set data lab
  setDataLab(state, payload) {
    state.dataLab = payload;
  },

  // set from API
  setLaboratoryServices(state, payload) {
    state.laboratoryServices = payload;
  },
  setDiagnose(state, payload) {
    const combinedArray = [...state.diagnose, ...payload];
    const uniqueArray = [...new Set(combinedArray.map(item => item._id))].map(
      id => {
        return combinedArray.find(item => item._id === id);
      },
    );
    state.diagnose = uniqueArray;
    // eslint-disable-next-line no-param-reassign
    payload = payload.filter(
      item => !uniqueArray.some(obj => obj._id === item._id),
    );
  },
  setLabReqById(state, payload) {
    state.labReqById = payload;
  },
  setDiagnoseCheckup(state, payload) {
    state.diagnoseCheckup = payload;
  },
  setLabResultById(state, payload) {
    state.labResultById = payload;
  },
  setInitialResultById(state, payload) {
    state.initialResultById = payload;
  },
  setStaff(state, payload) {
    state.staff = payload;
  },
  setLabRegistration(state, payload) {
    state.labRegistration = payload;
  },
};

const actions = {
  // Search laboratory services
  searchItem({ commit, state }) {
    let searchResult = [];
    state.laboratoryServices.map(item => {
      const { id, name, items } = item;
      const resultItem = {
        id,
        name,
        items: items.filter(itemExam =>
          itemExam.name
            .toLowerCase()
            .includes(state.search.searchQuery.toLowerCase()),
        ),
      };
      if (resultItem.items.length === 0) {
        return;
      } else {
        searchResult.push(resultItem);
      }
    });
    commit('setSearchResult', searchResult);
  },

  // Fetch data from API
  // Resolve get laboratory services
  async resolveGetLaboratoryServices({ commit }, assurance) {
    try {
      commit('setIsLoading', { key: 'laboratoryServices', value: true });
      const labServices = await getLaboratoryServices(assurance);
      const responseMapper = labServices?.map(item => ({
        id: _.uniqueId(),
        name: item?.category,
        items: item?.items.map(itemExam => ({
          id: itemExam?._id,
          name: itemExam?.name,
          category: item?.category,
        })),
      }));
      commit('setLaboratoryServices', responseMapper);
    } catch {
      commit('setLaboratoryServices', []);
    } finally {
      commit('setIsLoading', { key: 'laboratoryServices', value: false });
    }
  },

  // Resolve get no order
  async resolveGetNoOrder({ commit }) {
    try {
      const noOrder = await getNoOrder();
      commit('setNoOrder', noOrder);
    } catch {
      commit('setNoOrder', '');
    }
  },

  // Resolve get all diagnose
  async resolveGetDiagnose({ commit }, { keyword }) {
    commit('setIsLoading', { key: 'allDiagnose', value: true });
    try {
      const diagnose = await getDiagnose({ keyword });
      commit('setDiagnose', diagnose);
    } catch {
      commit('setDiagnose', []);
      commit('setIsLoading', { key: 'allDiagnose', value: false });
    } finally {
      commit('setIsLoading', { key: 'allDiagnose', value: false });
    }
  },

  // Resolve get diagnose checkup
  async resolveGetDiagnoseCheckup({ commit, state }) {
    commit('setIsLoading', { key: 'diagnoseCheckup', value: true });
    try {
      const diagnoseCheckup = await getDiagnoseCheckup(
        state.patientData.id_registration,
      );
      commit('setProvisionalDiagnosis', diagnoseCheckup);
      commit('setDiagnose', diagnoseCheckup);
    } catch {
      commit('setProvisionalDiagnosis', []);
      commit('setDiagnose', []);
    } finally {
      commit('setIsLoading', { key: 'diagnoseCheckup', value: false });
    }
  },

  // Resolve get lab request by id
  async resolveGetLabRequestById({ commit, state }) {
    try {
      const labRequestById = await getLabRequestById(
        state.dataLab?.id_registration === undefined
          ? state.patientData.id_registration
          : state.dataLab?.id_registration,
      );
      commit('setLabReqById', {
        ...labRequestById?.data,
        diagnosis:
          labRequestById?.data?.diagnosis.length !== 0
            ? labRequestById?.data?.diagnosis
            : [''],
      });
      commit('setDiagnose', labRequestById?.data?.diagnosis);
      commit(
        'setProvisionalDiagnosis',
        labRequestById?.data?.diagnosis.length !== 0
          ? labRequestById?.data?.diagnosis
          : [''],
      );
      commit('setInputOrderCheckedItems', labRequestById?.data?.test_item);
      commit('setInputResultNotes', labRequestById?.data?.clinical_notes);
      commit(
        'setInputResultRecommendation',
        labRequestById?.data?.recommendation,
      );
    } catch {
      commit('setLabReqById', {});
      commit('setDiagnose', []);
      commit('setProvisionalDiagnosis', []);
      commit('setInputOrderCheckedItems', []);
    }
  },

  // Resolve get staff
  async resolveGetStaff({ commit }) {
    try {
      const staff = await getStaff();
      const data = staff.map(item => {
        return {
          id: item._id,
          name: item.detail.name,
        };
      });
      commit('setStaff', data);
    } catch {
      commit('setStaff', []);
    }
  },

  // Resolve get lab result
  async resolveGetLabResult({ commit, state }) {
    try {
      const labResult = await getLabResult(state.dataLab.idLab);
      commit('setLabResultById', labResult);
      commit('setInitialResultById', labResult);
      commit('setInputResultNotes', labResult?.clinical_notes);
      commit('setInputResultRecommendation', labResult?.recommendation);
      commit('setInputResultStaff', labResult?.staff);
    } catch {
      commit('setLabResultById', {});
      commit('setInitialResultById', {});
      commit('setInputResultNotes', '');
      commit('setInputResultRecommendation', '');
      commit('setInputResultStaff', '');
    }
  },

  // Resolve get lab registration
  async resolveGetLabRegistration(
    { commit },
    { search = '', date = '', status = '', page = '1' },
  ) {
    commit('setIsLoading', { key: 'tableRegistration', value: true });
    try {
      const labRegistration = await getLabRegistration({
        search,
        date: date === null ? '' : date,
        status: status.toLocaleLowerCase(),
        page,
      });
      const labRegistrationMapped = labRegistration.map(item => {
        return {
          queye: item?.queye,
          noRM: item?.medical_record_number,
          orderCode: item?.order_code,
          name: item?.name,
          assurance: item?.assurance,
          isNew: item?.isNew,
          status: item?.status,
          idRegistration: item?.id_registration,
          noRegistration: item?.regist_number,
          idLab: item?.id_lab,
          age: item?.age,
          gender: item?.gender,
          birthDate: item?.birth_date,
          polyName: item?.unit?.name,
          hasGeneralConsent: item?.isGCCreated,
          guarantorType: item?.assurance,
          idEMR: item?.id_emr,
          timestamps: {
            createdAt: item?.timestamps?.created_at,
            createdBy: item?.timestamps?.created_by,
          },
        };
      });
      commit('setLabRegistration', labRegistrationMapped);
      commit('setIsLoading', { key: 'tableRegistration', value: false });
    } catch {
      commit('setLabRegistration', []);
      commit('setIsLoading', { key: 'tableRegistration', value: false });
    }
  },

  // Resolve post order lab
  async resolvePostOrderExamination({ commit, state }) {
    const checkedItemsWithName = state.inputOrder.checkedItems.map(item => {
      return {
        id_service: item,
        name: state.laboratoryServices
          .filter(
            service =>
              service.items.filter(itemService => itemService.id === item)
                .length > 0,
          )
          .map(service => {
            return service.items
              .filter(itemService => itemService.id === item)
              .map(itemService => itemService.name);
          })
          .flat()[0],
      };
    });
    const payload = {
      id_registration: state.patientData.id_registration,
      id_emr: state.patientData.id_emr,
      code: state.inputOrder.noOrder,
      diagnosis: state.inputOrder.provisionalDiagnosis.map(item => {
        return item._id;
      }),
      test_item: checkedItemsWithName,
    };
    try {
      const orderExamination = await postLabRequest(payload);
      if (orderExamination.status === 200) {
        commit('setShowDialog', false);
        commit('clearState');
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 2000);
          },
          text: 'Permintaan Laboratorium Berhasil Ditambahkan',
          color: 'success',
        });
      }
    } catch {
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 2000);
        },
        text: 'Permintaan Laboratorium Gagal Ditambahkan',
        color: 'error',
      });
    }
  },

  // Resolve update lab request
  async resolveUpdateLabRequest({ commit, state }) {
    const payload = {
      id_registration: state.patientData.id_registration,
      id_emr: state.patientData.id_emr,
      code: state.labReqById.code,
      diagnosis: state.inputOrder.provisionalDiagnosis
        .filter(item => item._id !== undefined)
        .map(item => {
          return item?._id;
        }),
      test_item: state.inputOrder.checkedItems
        .filter(item => item?.name !== undefined)
        .map(item => {
          return {
            id_service:
              item?.id_service === undefined ? item?.id : item?.id_service,
            name: item?.name,
          };
        }),
    };
    try {
      const orderExamination = await postLabRequest(payload);
      if (orderExamination.status === 200) {
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 2000);
          },
          text: 'Permintaan Laboratorium Berhasil Diubah',
          color: 'success',
        });
        commit('setIsChanged', false);
      }
    } catch {
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 2000);
        },
        text: 'Permintaan Laboratorium Gagal Diubah',
        color: 'error',
      });
    }
  },

  // Resolve confirm lab request
  async resolveConfirmLabRequest({ commit, state }) {
    const payload = {
      id_registration: state.dataLab.id_registration,
      id_emr: state.dataLab.id_emr,
      diagnosis: state.inputOrder.provisionalDiagnosis
        .filter(item => item._id !== undefined)
        .map(item => {
          return item?._id;
        }),
      test_item: state.inputOrder.checkedItems
        .filter(item => item?.name !== undefined)
        .map(item => {
          return {
            id_service:
              item?.id_service === undefined ? item?.id : item?.id_service,
            name: item?.name,
            category: item?.category,
          };
        }),
    };
    try {
      const confirmLabRequest = await putConfirmLabRequest(
        state.dataLab.idLab,
        payload,
      );
      if (confirmLabRequest.status === 200) {
        commit('setShowDialog', false);
        commit('clearState');
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 2000);
          },
          text: 'Permintaan Laboratorium Berhasil Dikonfirmasi',
          color: 'success',
        });
      }
    } catch {
      commit('setShowDialog', false);
      commit('clearState');
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 2000);
        },
        text: 'Permintaan Laboratorium Gagal Dikonfirmasi',
        color: 'error',
      });
    }
  },

  // Resolve post lab result
  async resolvePostLabResult({ commit, state }, { isInputed }) {
    const payload = {
      id_registration: state.dataLab.id_registration,
      code: state.dataLab.orderNumber,
      id_emr: state.dataLab.id_emr,
      id_staff: _.isObject(state.inputResult.staff)
        ? state.inputResult.staff.id
        : state.inputResult.staff,
      test_item: state.inputResult.labResultTestItem.flatMap(
        category => category,
      ),
      clinical_notes: state.inputResult.notes,
      recommendation: state.inputResult.recommendation,
    };
    try {
      const labResult = await postLabResult(state.dataLab.idLab, payload);
      if (labResult.status === 200) {
        if (isInputed === false) {
          commit('setShowDialog', false);
          commit('clearState');
        }
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 2000);
          },
          text: `Hasil Permintaan Laboratorium Berhasil ${
            isInputed === true ? 'Diubah' : 'Disimpan'
          }`,
          color: 'success',
        });
        commit('setInitialResultById', labResult?.data?.data);
        commit('setLabResultById', labResult?.data?.data);
        commit('setInputResultIsChanged', true);
      }
    } catch {
      if (isInputed === false) {
        commit('setShowDialog', false);
        commit('clearState');
      }
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 2000);
        },
        text: `Hasil Permintaan Laboratorium Gagal ${
          isInputed === true ? 'Diubah' : 'Disimpan'
        }`,
        color: 'error',
      });
      commit('setInitialResultById', {});
      commit('setInputResultIsChanged', true);
    }
  },

  // Resolve post lab result
  async resolvePostLabRegistration({ commit, state }, patientData) {
    const payload = {
      id_emr: patientData.id_emr,
      patient: patientData.meta,
      status: {
        isNew: patientData.status.isNew,
        type: patientData.status.type,
      },
      lab: {
        diagnosis: state.inputOrder.provisionalDiagnosis
          .filter(item => item._id !== undefined)
          .map(item => {
            return item?._id;
          }),
        test_item: state.inputOrder.checkedItems
          .filter(item => item?.name !== undefined)
          .map(item => {
            return {
              id_service:
                item?.id_service === undefined ? item?.id : item?.id_service,
              name: item?.name,
            };
          }),
      },
    };
    try {
      const labRegistration = await postLabRegistration(payload);
      if (labRegistration.status === 200) {
        commit('setShowDialog', false);
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 2000);
          },
          text: 'Permintaan Pemeriksaan Laboratorium Berhasil Diorder',
          color: 'success',
        });
        commit('setInputResultIsChanged', true);
      }
    } catch {
      commit('setShowDialog', false);
      commit('clearState');
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 2000);
        },
        text: 'Permintaan Pemeriksaan Laboratorium Gagal Diorder',
        color: 'error',
      });
    }
  },

  async resolveDeleteRegisterLab({ commit, state }, idLab) {
    try {
      const deleteRegistLab = await deleteRegisterLab(idLab);
      if (deleteRegistLab.status === 200) {
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 2000);
          },
          text: 'Permintaan laboratorium berhasil dibatalkan',
          color: 'success',
        });
        commit('setIsChanged', false);
      }
    } catch {
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 2000);
        },
        text: 'Permintaan laboratorium gagal dibatalkan',
        color: 'error',
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
