<template>
  <v-container fluid class="py-6 px-7" style="position:relative">
    <v-row>
      <v-col class="d-flex justify-start align-center">
        <v-btn
          class="mr-3"
          small
          v-if="isResend"
          text
          fab
          light
          depressed
          @click="navigate"
          ><v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>
          {{
            isResend
              ? 'Pengiriman Ulang Data SATUSEHAT'
              : 'Dashboard Bridging SATUSEHAT'
          }}
        </h2>
      </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <template v-if="isResend">
          <div class="date-resend-chip">
            {{ selectedTimeFilter }}
          </div>
        </template>
        <template v-else>
          <v-btn
            @click="refreshAndAutoUpdate"
            text
            fab
            small
            class="mr-2"
            :class="{ refresh: loading.data }"
            ><v-icon color="grey">mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Cari nama pasien atau no RM"
            prepend-inner-icon="mdi-magnify"
            class="mr-2"
            width="1000"
            solo
            dense
            hide-details
            flat
            single-line
            :loading="loading.data"
            :disabled="loading.data"
            v-model="search"
          />
          <v-btn
            color="primary"
            outlined
            class="text-capitalize"
            :loading="loading.data"
            >Export</v-btn
          >
        </template>
      </v-col>
    </v-row>
    <v-row v-if="!isResend">
      <v-col class="text-start d-flex">
        <v-btn
          style="color: white;height: 3.8vh; width: 8vw; font-size: .75vw;"
          @click="navigate"
          :loading="loading.data"
          :color="resendButtonColor"
          dense
          depressed
          light
          class="text-capitalize"
          >Kirim Ulang ({{ totalUnsolved }})
          <v-icon class="ml-1">mdi-arrow-right</v-icon>
        </v-btn>
        <div
          v-if="!loading.data && totalProcessed > 0"
          class="ml-3 mb-0 alert"
          dense
          text
          type="primary"
        >
          <v-icon class="mr-2" color="primary">mdi-alert-circle</v-icon>
          <p class="ma-0 pa-0">
            {{ totalProcessed }} data sedang diproses, refresh berkala untuk
            melihat hasilnya
          </p>
        </div>
      </v-col>
      <v-col :cols="filter.type === 'Rentang Tanggal' ? 5 : 3" class="d-flex">
        <v-combobox
          single-line
          dense
          hide-details
          append-icon="mdi-chevron-down"
          class="mr-3"
          :items="resource.filterType"
          v-model="filter.type"
          outlined
          :loading="loading.data"
          :disabled="loading.data"
        />
        <div>
          <v-menu
            dense
            v-if="
              filter.type === 'Per Tanggal' || filter.type === 'Rentang Tanggal'
            "
            ref="menu_startdate"
            v-model="menu.startDate"
            :close-on-content-click="true"
            :return-value.sync="startDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="startDate.formatted"
                prepend-inner-icon="mdi-calendar"
                :placeholder="
                  filter.type === 'Per Tanggal'
                    ? 'Pilih Tanggal'
                    : 'Tanggal Awal'
                "
              />
            </template>
            <v-date-picker
              v-model="startDate.raw"
              locale="id"
              :max="filter.type === 'Rentang Tanggal' ? endDate.raw : ''"
              scrollable
              @input="$refs.menu_startdate.save(formatDate(startDate.raw))"
            ></v-date-picker>
          </v-menu>
        </div>
        <span v-if="filter.type === 'Rentang Tanggal'" class="pt-3 mx-3"
          >s.d
        </span>
        <div>
          <v-menu
            dense
            v-if="filter.type === 'Rentang Tanggal'"
            ref="menu_enddate"
            v-model="menu.endDate"
            :close-on-content-click="true"
            :return-value.sync="endDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="endDate.formatted"
                prepend-inner-icon="mdi-calendar"
                placeholder="Tanggal Akhir"
              />
            </template>
            <v-date-picker
              v-model="endDate.raw"
              locale="id"
              :min="startDate.raw"
              scrollable
              @input="$refs.menu_enddate.save(formatDate(endDate.raw))"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-menu
          dense
          v-if="filter.type === 'Per Bulan'"
          ref="menu_month"
          v-model="menu.month"
          :close-on-content-click="true"
          :return-value.sync="month.formatted"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              flat
              single-line
              solo
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              v-model="month.formatted"
              prepend-inner-icon="mdi-calendar"
              placeholder="Pilih Bulan"
            />
          </template>
          <v-date-picker
            v-model="month.raw"
            type="month"
            locale="id"
            scrollable
            @input="$refs.menu_month.save(formatMonth(month.raw))"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-progress-linear indeterminate v-if="loading.data" />
        <div class="table-header">
          <span>No.</span>
          <span>Tgl Masuk</span>
          <span>Tgl Keluar</span>
          <span>No RM</span>
          <span>Nama Pasien</span>
          <span>No BPJS</span>
          <span>No NIK</span>
          <span>Status Bridging</span>
        </div>
        <div :class="`table-body ${isResend ? 'resend' : ''}`">
          <div
            class="table-row"
            v-for="(item, index) in dataTable"
            :key="`patient-${index}`"
          >
            <span>{{ item.no }}</span>
            <span>{{ item.datein }}</span>
            <span>{{ item.dateout }}</span>
            <span>{{ item.rmNumber }}</span>
            <span>{{ item.name }}</span>
            <span>{{ item.bpjs }}</span>
            <span>{{ item.nik }}</span>
            <v-tooltip v-if="item.bridgingStatus?.status === 'Gagal'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="chip fail" v-bind="attrs" v-on="on">
                  {{ item.bridgingStatus.status }}
                  <v-icon small color="red" class="pb-1"
                    >mdi-alert-circle-outline</v-icon
                  >
                </div>
              </template>
              <span>{{ item.bridgingStatus?.message }}</span>
            </v-tooltip>
            <div
              v-else
              :class="
                `chip ${item.bridgingStatus === 'Diproses' ? 'processed' : ''}`
              "
            >
              {{ item.bridgingStatus }}
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openForm(item, 0)"
                  v-bind="attrs"
                  v-on="on"
                  light
                  text
                  fab
                  small
                >
                  <v-icon color="#9ca2a5">mdi-account </v-icon>
                </v-btn>
              </template>
              <span>Data Sosial</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  @click="openForm(item, 1)"
                  v-on="on"
                  light
                  small
                  fab
                  text
                >
                  <font-awesome-icon
                    style="font-size: .9vw;"
                    color="#9ca2a5"
                    icon="notes-medical"
                  />
                </v-btn>
              </template>
              <span>Assesmen</span>
            </v-tooltip>
          </div>
          <div v-if="!dataTable.length" class="grey--text pt-4">
            {{ loading.data ? 'Sedang memuat...' : 'Data tidak ditemukan' }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 d-flex justify-start">
        <v-pagination
          v-if="pageLength"
          v-model="pagination.page"
          :length="pageLength"
          @input="refreshAndAutoUpdate"
          total-visible="10"
        ></v-pagination>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          v-if="isResend"
          @click="resend"
          :loading="loading.resend"
          color="primary"
          depressed
          class="text-capitalize"
        >
          Kirim Ulang
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      max-width="1500"
      v-model="dialog"
      :fullscreen="selectedForm ? true : false"
      persistent
      v-if="dialog"
    >
      <social-data
        v-if="selectedForm === 0"
        @close-form="closeForm"
        :medical-record="selectedPatient?.rmNumber"
      />
      <general-poly-assestment
        @close-form="closeForm"
        v-if="selectedForm === 1"
        :id-registration="selectedPatient?.idRegistration"
        :rm-number="selectedPatient?.rmNumber"
        is-ihs
      />
      <kia-poly-assessment
        @close-form="closeForm"
        v-if="selectedForm === 2"
        :id-registration="selectedPatient?.idRegistration"
        :rm-number="selectedPatient?.rmNumber"
        is-ihs
      />
      <dental-poly-assestment
        @close-form="closeForm"
        v-if="selectedForm === 3"
        :id-registration="selectedPatient?.idRegistration"
        :rm-number="selectedPatient?.rmNumber"
        is-ihs
      />
    </v-dialog>
    <v-snackbar :timeout="4000" v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
// components
import SocialData from '../../SharedComponent/SocialData';
import GeneralPolyAssestment from '../../Service/components/GeneralPoly/GeneralPolyAssestment';
import KiaPolyAssessment from '../../Service/components/KIApoly/Assessment.vue';
import DentalPolyAssestment from '../../Service/components/DentalPoly/DentalPolyAssestment';

// api
import {
  getSatuSehatPatients,
  getUnsolvedSatuSehatPatients,
  getProcessedIhsCount,
  // postResend,
} from '@/fetchApi/IHS';

// mixins
import Mapper from '@/mixin/MapperMixins/IHS';

// utils
const moment = require('moment-timezone');
const _ = require('lodash');
import Swal from 'sweetalert2';

export default {
  name: 'DashboardSatuSehat',
  components: {
    SocialData,
    GeneralPolyAssestment,
    KiaPolyAssessment,
    DentalPolyAssestment,
  },
  mixins: [Mapper],
  data() {
    return {
      search: '',
      selectedPatient: null,
      selectedForm: null,
      dialog: false,
      loading: {
        data: false,
      },
      resource: {
        filterType: ['Per Tanggal', 'Per Bulan', 'Rentang Tanggal'],
      },
      filter: {
        type: 'Per Tanggal',
      },
      month: {
        raw: '',
        formatted: '',
      },
      menu: {
        month: false,
        endDate: false,
        startDate: false,
      },
      startDate: {
        raw: moment().format('YYYY-MM-DD'),
        formatted: moment().format('DD-MM-YYYY'),
      },
      endDate: {
        raw: '',
        formatted: '',
      },
      patients: {
        all: [],
        unsolved: [],
      },
      pagination: {
        page: 1,
        length: {
          all: 0,
          unsolved: 0,
        },
      },
      snackbar: {
        show: null,
        color: null,
        message: null,
      },
      isResend: false,
      totalUnsolved: 0,
      totalProcessed: 0,
      interval: null,
    };
  },
  watch: {
    'filter.type'(value) {
      if (value === 'Per Tanggal') {
        this.startDate = {
          raw: moment().format('YYYY-MM-DD'),
          formatted: moment().format('DD-MM-YYYY'),
        };
      } else {
        this.startDate = {
          raw: '',
          formatted: '',
        };
        this.endDate = {
          raw: '',
          formatted: '',
        };
      }
    },
    isResend() {
      this.pagination.page = 1;
      this.refreshAndAutoUpdate();
    },
    search() {
      this.pagination.page = 1;
      this.searchData(this);
    },
    'month.formatted'(val) {
      if (!val) return;
      this.onChangeFilter();
    },
    'startDate.formatted'(val) {
      if (!val) return;
      if (this.filter.type === 'Per Tanggal') {
        this.onChangeFilter();
      }
      if (this.filter.type === 'Rentang Tanggal' && !this.endDate.formatted)
        return;
      this.onChangeFilter();
    },
    'endDate.formatted'(val) {
      if (!val) return;
      if (this.filter.type === 'Rentang Tanggal' && !this.startDate.formatted)
        return;
      this.onChangeFilter();
    },
  },
  computed: {
    resendButtonColor: {
      get() {
        if (this.totalProcessed) return '#9e9e9e';
        return this.totalUnsolved ? 'primary' : '#9e9e9e';
      },
    },
    selectedTimeFilter: {
      get() {
        let result;
        switch (this.filter.type) {
          case 'Per Tanggal':
            result = moment(this.startDate.raw, 'YYYY-MM-DD').format(
              'DD MMMM YYYY',
            );
            break;
          case 'Per Bulan':
            result = moment(this.month.raw, 'YYYY-MM').format('MMMM YYYY');
            break;
          case 'Rentang Tanggal':
            result = `${moment(this.startDate.raw, 'YYYY-MM-DD').format(
              'DD MMMM YYYY',
            )} - ${moment(this.endDate.raw, 'YYYY-MM-DD').format(
              'DD MMMM YYYY',
            )}`;
        }
        return result;
      },
    },
    dataTable: {
      get() {
        return this.isResend ? this.patients.unsolved : this.patients.all;
      },
    },
    pageLength: {
      get() {
        return this.isResend
          ? this.pagination.length.unsolved
          : this.pagination.length.all;
      },
    },
    itemsPerPage() {
      let items = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          items = 5;
          break;
        case 'lg':
          items = 7;
          break;
        case 'xl':
          items = 9;
          break;
        default:
          return 5;
      }
      return items;
    },
  },
  mounted() {
    this.refreshAndAutoUpdate();
    this.listeningToSocket();
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      if (v.isResend) {
        v.getUnsolvedPatients();
      } else {
        v.getPatients();
      }
    }, 650),
    updateTableBySocket({ id_registration, success_at, amount, message }) {
      this.totalProcessed = amount - 1 || 0;
      this.patients.all = this.patients.all.map(item => {
        if (item.idRegistration === id_registration) {
          return {
            ...item,
            bridgingStatus: success_at
              ? moment(success_at).format('DD-MM-YYYY')
              : { status: 'Gagal', message },
          };
        } else {
          return item;
        }
      });
      this.patients.unsolved = this.patients.unsolved.map(item => {
        if (item.idRegistration === id_registration) {
          return {
            ...item,
            bridgingStatus: success_at
              ? moment(success_at).format('DD-MM-YYYY')
              : { status: 'Gagal' },
          };
        } else {
          return item;
        }
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }

      this.getAllData();
      this.interval = setInterval(this.getAllData, 60000);
    },
    async onChangeFilter() {
      this.pagination.page = 1;
      await this.getAllData();
    },
    openForm(patient, form) {
      this.selectedPatient = {
        rmNumber: patient.rmNumber,
        idRegistration: patient.idRegistration,
      };

      if (form) {
        // if from has value, it means user is opening assessment
        this.selectedForm = this.selectAssessment(patient.poly);
      } else {
        this.selectedForm = form;
      }

      this.dialog = true;
    },
    selectAssessment(poly) {
      switch (poly.toLowerCase()) {
        case 'poli umum':
          return 1;
        case 'poli kia':
          return 2;
        case 'poli gigi':
          return 3;
      }
    },
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    resend() {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Kirim Ulang Data</p></div>`,
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Hasil tidak langsung muncul di halaman Anda,</p><p>silakan tunggu beberapa saat dan refresh</p><p>halaman untuk melihat hasilnya</p></div>',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Kirim',
        cancelButtonText: 'Batal',
      }).then(async result => {
        try {
          if (result.isConfirmed) {
            const params = {
              month:
                this.filter.type === 'Per Bulan'
                  ? this.month.formatted
                  : undefined,
              startDate:
                this.filter.type === 'Rentang Tanggal'
                  ? this.startDate.formatted
                  : undefined,
              endDate:
                this.filter.type === 'Rentang Tanggal'
                  ? this.endDate.formatted
                  : undefined,
              date:
                this.filter.type === 'Per Tanggal'
                  ? this.startDate.formatted
                  : undefined,
            };
            this.$socket.client.emit('message:resend-ihs', { query: params });
            this.snackbar = {
              show: true,
              color: '#31b057',
              message: 'Data berhasil dikirim ulang',
            };
          }

          // navigate back to dashboard and refresh the page
          this.isResend = !this.isResend;
        } catch {
          this.snackbar = {
            show: true,
            color: 'error',
            message: 'Terjadi kesalahan, data gagal dikirim ulang',
          };
        } finally {
          this.refreshAndAutoUpdate();
        }
      });
    },
    listeningToSocket() {
      this.$socket.client.on(
        'message:resend-refresh',
        ({ id_registration, success_at, amount, message }) => {
          this.updateTableBySocket({
            id_registration,
            success_at,
            amount,
            message,
          });
        },
      );
      this.$socket.client.on('message:finish-processing', () => {
        this.refreshAndAutoUpdate();
      });
      this.$socket.client.on('message:resend-finish', () => {
        this.snackbar = {
          show: true,
          color: '#31b057',
          message: 'Proses pengiriman ulang data telah selesai',
        };
      });
    },
    async getProcessedData() {
      try {
        const params = {
          month:
            this.filter.type === 'Per Bulan' ? this.month.formatted : undefined,
          startDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.startDate.formatted
              : undefined,
          endDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.endDate.formatted
              : undefined,
          date:
            this.filter.type === 'Per Tanggal'
              ? this.startDate.formatted
              : undefined,
        };
        const response = await getProcessedIhsCount(params);
        this.totalProcessed = response.data?.data?.jumlah || 0;
      } catch {
        this.totalProcessed = 0;
      }
    },
    getAllData() {
      this.getUnsolvedPatients();
      this.getPatients();
      this.getProcessedData();
    },
    async getUnsolvedPatients() {
      try {
        this.loading.data = true;

        const params = {
          page: this.isResend ? this.pagination.page : 1,
          itemCount: this.itemsPerPage,
          search: this.search || '',
          month:
            this.filter.type === 'Per Bulan' ? this.month.formatted : undefined,
          startDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.startDate.formatted
              : undefined,
          endDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.endDate.formatted
              : undefined,
          date:
            this.filter.type === 'Per Tanggal'
              ? this.startDate.formatted
              : undefined,
        };

        const response = await getUnsolvedSatuSehatPatients(params);
        const { length } = response.data;
        this.pagination.length.unsolved = Math.ceil(length / this.itemsPerPage); // assign for pagination
        this.totalUnsolved = length || 0; // assign total unsolved patients
        this.patients.unsolved = this.mapSatuSehatPatients(response.data.data); // main mapping
      } catch {
        this.pagination.length.unsolved = 0; // assign for pagination
        this.totalUnsolved = 0; // assign total unsolved patients
        this.patients.unsolved = [];
      } finally {
        this.loading.data = false;
      }
    },
    async getPatients() {
      try {
        this.loading.data = true;

        const params = {
          page: this.pagination.page,
          itemCount: this.itemsPerPage,
          search: this.search || '',
          month:
            this.filter.type === 'Per Bulan' ? this.month.formatted : undefined,
          startDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.startDate.formatted
              : undefined,
          endDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.endDate.formatted
              : undefined,
          date:
            this.filter.type === 'Per Tanggal'
              ? this.startDate.formatted
              : undefined,
        };

        const response = await getSatuSehatPatients(params);
        const { length } = response.data;
        this.pagination.length.all = Math.ceil(length / this.itemsPerPage); // assign for pagination
        this.patients.all = this.mapSatuSehatPatients(response.data.data); // main mapping
      } catch {
        this.pagination.length.all = 0; // assign for pagination
        this.patients.all = [];
      } finally {
        this.loading.data = false;
      }
    },
    navigate() {
      if (!this.totalUnsolved || this.totalProcessed) return;
      this.isResend = !this.isResend;
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    formatMonth(month) {
      return moment(month, 'YYYY-MM').format('MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: grid;
  grid-template-columns: 4% repeat(3, 9%) 30% repeat(2, 12%) auto;
  background-color: #f5f5f5;
  text-align: start;
  font-size: 0.75vw;
  align-items: center;
  padding: 0.5vw 1vw;
  font-weight: 500;
  color: #9e9e9e;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.table-body {
  height: 60vh;
  overflow-y: auto;

  &.resend {
    height: 66vh;
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: rgb(123, 121, 121);
  }
}

.table-row {
  width: 100%;
  display: grid;
  text-align: start;
  align-items: center;
  font-size: 0.75vw;
  padding: 0.5vw 1vw;
  border-bottom: 1px solid #e0e0e0;
  grid-template-columns: 4% repeat(3, 9%) 30% repeat(2, 12%) 7% repeat(2, 3%);
  overflow: hidden;

  .chip {
    padding: 0.3vw;
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
    font-weight: 500;
    background-color: #e5fff0;
    text-align: center;
    color: #2d965a;

    &.fail {
      color: #eb4646;
      background-color: #fff2f2;
    }
    &.processed {
      color: #e5a82e;
      background-color: #fff9e5;
    }
  }
}

button {
  letter-spacing: 0.01px;
}

.alert {
  color: black !important;
  height: 3.8vh;
  padding: 1vh 0.7vw;
  background-color: #ebf3f9 !important;
  border: 1px solid #cecece;
  font-size: 0.8vw;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-resend-chip {
  padding: 0.4vw 0.5vw;
  background-color: #f5f5f5;
  font-weight: 500;
  border-radius: 6px;
  color: #404040;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::v-deep .v-snack__content {
  padding: 6px 10px !important;
  display: flex;
  justify-content: center;
}
</style>
