import bpjsLogo from '@/assets/image/bpjsLogo.js';
import moment from 'moment-timezone';
import Canvas from 'canvas';
import JsBarcode from 'jsbarcode';
import JsPDF from 'jspdf';
import printMixin from './printMixin';
import QRCode from 'qrcode';

export default {
  methods: {
    generateReferralLetterBpjs(data, isBack) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const clientData = this.$store.getters.userLoggedIn.clinic;

      const marginX = 0.5;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setProperties({
        title: `Surat Rujukan BPJS no.RM: ${data.medical_record_number}`,
      });
      doc.addImage(bpjsLogo, 'png', marginX, 0.5, 2.6, 0.4);
      doc.autoTable({
        body: [
          [
            { content: 'Kedeputian Wilayah', styles: { fontStyle: 'bold' } },
            `${clientData?.bpjs?.regional_deputy}`,
          ],
          [
            { content: 'Kantor Cabang', styles: { fontStyle: 'bold' } },
            clientData?.bpjs?.branch,
          ],
        ],
        startY: marginX,
        styles: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 1.5, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 2, cellPadding: { bottom: 0.05 } },
        },

        margin: {
          left: 4.5,
        },
        theme: 'plain',
      });
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(14);
      alignCenter('Surat Rujukan FKTP', undefined, 1.5);
      doc.setDrawColor(0, 0, 0);

      doc.setLineWidth(0.001);
      doc.rect(marginX, 1.75, 7.25, 5); // first rectangle
      if (isBack) doc.rect(marginX, 6.74, 7.25, 4.3); // second rectangle

      doc.setDrawColor(0, 0, 0);
      doc.rect(marginX + 0.25, 2, 6.75, 0.75);

      doc.autoTable({
        body: [
          ['Nomor Rujukan', ':', `${data.letter_number}`],
          [
            'FKTP',
            ':',
            `${clientData?.bpjs?.fktp_name} (${clientData?.bpjs?.fktp_code})`,
          ],
          [
            'Kabupaten/Kota',
            ':',
            `${clientData?.bpjs?.city_name} ${clientData?.bpjs?.city_code}`,
          ],
        ],
        startY: addLine(5),
        columnStyles: {
          0: { cellWidth: 1.5, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 0.15, cellPadding: { bottom: 0.05 } },
          2: { cellWidth: 2, cellPadding: { bottom: 0.05 } },
        },
        margin: {
          left: marginX + 0.5,
        },
        styles: {
          fontSize: 8,
        },
        theme: 'plain',
      });

      const transformRm = Canvas.createCanvas();
      JsBarcode(transformRm, `${data.letter_number}`, {
        height: 20,
        font: 'times',
        width: 1.15,
        margin: 3,
        displayValue: false,
        fontOptions: 'bold',
        fontSize: 9,
      });
      const jpegUrl = transformRm.toDataURL('image/jpeg');
      doc.addImage(jpegUrl, 'JPEG', 4.9, 2.22);

      const lastTable = doc.autoTable.previous.finalY;
      doc.autoTable({
        body: [
          [
            'Kepada Yth. TS Dokter',
            ':',
            `${
              data.detail.reference_type === 'specialist'
                ? data.detail.referral_sub_specialist
                : data.detail.referral_specific
            }`,
          ],
          ['Di', ':', `${data.detail.instansi_name}`],
        ],
        startY: lastTable + 0.25,
        columnStyles: {
          0: { cellWidth: 1.5, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 0.15, cellPadding: { bottom: 0.05 } },
          2: { cellWidth: 2, cellPadding: { bottom: 0.05 } },
        },
        margin: {
          left: marginX + 0.5,
        },
        styles: {
          fontSize: 8,
        },
        theme: 'plain',
      });

      doc.setFontSize(8);
      doc.setFont('Helvetica', 'normal');
      doc.text(
        'Mohon pemeriksaan dan penanganan lebih lanjut pasien :',
        marginX + 0.5,
        lastTable + 1,
      );
      doc.autoTable({
        body: [
          ['Nama', ':', `${data.patient.name}`],
          ['No. Kartu BPJS', ':', `${data.patient.noBpjs}`],
          [
            'Diagnosa',
            ':',
            `${data.detail.diagnose.text} (${data.detail.diagnose.code}) ${
              data.detail.secDiagnose1
                ? `${data.detail.secDiagnose1.description} (${data.detail.secDiagnose1.code})`
                : ''
            } ${
              data.detail.secDiagnose2
                ? `${data.detail.secDiagnose2.description} (${data.detail.secDiagnose2.code})`
                : ''
            }`,
          ],
          ['Telah diberikan', ':', `${data.detail.therapy}`],
        ],
        startY: lastTable + 1.1,
        columnStyles: {
          0: { cellWidth: 1.5, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 0.15, cellPadding: { bottom: 0.05 } },
          2: { cellWidth: 1.3, cellPadding: { bottom: 0.05 } },
        },
        margin: {
          left: marginX + 0.5,
        },
        styles: {
          fontSize: 8,
        },
        theme: 'plain',
      });
      // doc.setLineDashPattern([0.1, 0.1]);

      doc.autoTable({
        body: [
          ['Umur', ':', `${data.patient.age} Tahun`],
          ['Status', ':', ''],
          [],
          ['Catatan', ':', `${data.detail.suggestions}`],
        ],
        startY: lastTable + 1.1,
        columnStyles: {
          0: { cellWidth: 0.5, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 0.15, cellPadding: { bottom: 0.05 } },
          2: { cellWidth: 2.7, cellPadding: { bottom: 0.05 } },
        },
        margin: {
          left: 4,
        },
        theme: 'plain',
        styles: {
          fontSize: 8,
        },
      });

      doc.setDrawColor(0, 0, 0);
      doc.rect(4.7, lastTable + 1.3, 0.3, 0.25);
      doc.rect(6.4, lastTable + 1.3, 0.3, 0.25);

      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(8);

      doc.text(
        `Tahun         : ${moment(data.patient.birth.date, 'YYYY-MM-DD').format(
          'DD-MMM-YYYY',
        )}`,
        5.9,
        lastTable + 1.2,
      );
      doc.text(
        `Utama/Tanggungan                  (L/P)`,
        5.25,
        lastTable + 1.4,
      );
      doc.text(`${data.patient.gender ? 'L' : 'P'}`, 6.5, lastTable + 1.45);

      const lastTableTwo = doc.autoTable.previous.finalY + 0.7;
      doc.setTextColor('red');
      if (data.detail.taccName && data.detail.taccReason) {
        doc.text(
          `# Alasan Rujuk Diagnosa Non-Spesialistik : (${data.detail.taccName}) ${data.detail.taccReason}`,
          marginX + 0.5,
          lastTableTwo + 0.3,
        );
      }
      doc.setTextColor('black');

      doc.text(
        'Atas bantuannya, diucapkan terima kasih',
        marginX + 0.5,
        lastTableTwo + 0.5,
      );
      doc.autoTable({
        body: [
          [
            'Tgl. Rencana Berkunjung',
            ':',
            `${moment(data.detail.referral_plan_date, 'DD-MM-YYYY')
              .locale('id')
              .format('DD-MMM-YYYY')}`,
          ],
          ['Jadwal Praktek', ':', `${data.detail.schedule_check}`],
        ],
        startY: lastTableTwo + 0.6,
        columnStyles: {
          0: { cellWidth: 1.75, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 0.15, cellPadding: { bottom: 0.05 } },
          2: { cellWidth: 2, cellPadding: { bottom: 0.05 } },
        },
        margin: {
          left: marginX + 0.5,
        },
        styles: {
          fontSize: 8,
        },
        theme: 'plain',
      });

      const lastTableThree = doc.autoTable.previous.finalY;

      const date = moment(data.detail.timestamps.created_at).format(
        'DD-MM-YYYY',
      );
      doc.text(
        `Surat rujukan berlaku 1[satu] kali kunjungan, berlaku sampai dengan : ${moment(
          date,
          'DD-MM-YYYY',
        )
          .add(89, 'days')
          .format('DD-MMM-YYYY')}`,
        marginX + 0.5,
        lastTableThree + 0.1,
      );

      doc.setFontSize(8);
      doc.setFont('Helvetica', 'normal');
      alignCenter('Salam sejawat,', undefined, lastTableThree - 0.5, 2);
      alignCenter(
        `${moment(date, 'DD-MM-YYYY')
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        lastTableThree - 0.35,
        2,
      );
      alignCenter(
        `${this.$store.getters.userLoggedIn.name}`,
        undefined,
        lastTableThree + 0.15,
        2,
      );
      const lastLine = doc.autoTable.previous.finalY;

      // Surat Rujuk Balik
      if (isBack) {
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(13);
        alignCenter('SURAT RUJUKAN BALIK', undefined, 7.15);
        doc.setLineWidth(0.023);
        doc.setDrawColor('black');
        doc.line(3.05, lastLine + 1.1, 5.25, lastLine + 1.1);

        doc.setFontSize(8);
        doc.setFont('Helvetica', 'normal');
        doc.text('Teman sejawat Yth.', marginX + 0.5, lastTable + 5);
        doc.text(
          'Mohon kontrol selanjutnya penderita :',
          marginX + 0.5,
          lastTable + 5.15,
        );
        doc.autoTable({
          body: [
            ['Nama', ':', `${data.patient.name}`],
            ['Diagnosa', ':', ''],
            ['Terapi', ':', ''],
          ],
          startY: lastTable + 5.3,
          columnStyles: {
            0: { cellWidth: 1.5, cellPadding: { bottom: 0.055 } },
            1: { cellWidth: 0.1, cellPadding: { bottom: 0.055 } },
            2: { cellWidth: 3, cellPadding: { bottom: 0.055 } },
          },
          margin: {
            left: marginX + 0.9,
          },
          styles: {
            fontSize: 8,
          },
          theme: 'plain',
        });

        doc.text(
          'Tindak lanjut yang dianjurkan',
          marginX + 0.7,
          lastTable + 6.1,
        );
        doc.text(
          'Pengobatan dengan obat-obatan :',
          marginX + 1.1,
          lastTable + 6.355,
        );
        doc.text('Perlu rawat inap', marginX + 1.1 + 3.6, lastTable + 6.355);
        doc.text('Konsultasi selesai', marginX + 1.1 + 3.6, lastTable + 6.69);
        doc.setFontSize(11);
        doc.text(
          '...................................................',
          marginX + 1.1,
          lastTable + 6.655,
        );
        doc.setFontSize(8);
        doc.text(
          'Kontrol kembali ke RS tanggal :',
          marginX + 1.1,
          lastTable + 6.8,
        );
        doc.setFontSize(11);
        doc.text('.............', marginX + 2.7, lastTable + 6.8);
        doc.setFontSize(8);
        doc.text('Lain-lain :', marginX + 1.1, lastTable + 7.24);
        doc.setFontSize(11);
        doc.text(
          '.......................................',
          marginX + 1.6,
          lastTable + 7.24,
        );
        doc.text('................', marginX + 4.3, lastTable + 7.15);
        doc.text('................', marginX + 5.1, lastTable + 7.15);
        doc.setFontSize(8);
        doc.text('tgl', marginX + 5, lastTable + 7.15);
        doc.text('Dokter RS,', marginX + 5.2, lastTable + 7.5);
        doc.text(
          '(...................................)',
          marginX + 4.9,
          lastTable + 8.1,
        );
        doc.setDrawColor(0, 0, 0);
        doc.rect(1.2, lastTable + 6.27, 0.3, 0.25);
        doc.rect(4.8, lastTable + 6.27, 0.3, 0.25);
        doc.rect(1.2, lastTable + 6.6, 0.3, 0.25);
        doc.rect(4.8, lastTable + 6.6, 0.3, 0.25);
        doc.rect(1.2, lastTable + 7, 0.3, 0.25);
        // dashed line
        doc.setLineDashPattern([0.015, 0.03]);
        doc.setLineWidth(0.0001);
        doc.setDrawColor('black');
        doc.line(3, lastLine + 2.1, 7, lastLine + 2.1);
        doc.line(3, lastLine + 2.29, 7, lastLine + 2.29);
      }

      return doc;
    },
    generateReferralLetterNonBpjs(data) {
      const clientData = this.$store.getters.userLoggedIn.clinic;

      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });
      const marginX = 1;
      const marginY = 1.7;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };
      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('SURAT RUJUKAN', undefined, 1.85);
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text(`Nomor Surat     : ${data.letter_number}`, marginX, addLine(6));
      doc.text(
        `Nomor RM      : ${data.letter_number}`,
        doc.internal.pageSize.getWidth() - 1.1,
        addLine(6),
        { align: 'right' },
      );
      doc.text(
        `Kepada Yth. Dokter ${
          data.detail.reference_type === 'specialist'
            ? data.detail.referral_sub_specialist
            : data.detail.referral_specific
        }`,
        marginX,
        addLine(8),
      );
      doc.text(`Di  ${data.detail.instansi_name}`, marginX, addLine(9));
      doc.text(
        'Mohon pemeriksaan dan penanganan lebih lanjut pasien :',
        marginX,
        addLine(11),
      );
      let lastDidDrawPageData = null;
      doc.autoTable({
        body: [
          ['Nama', ':', `${data.patient.name}`],
          ['Usia', ':', data.patient.age],
          ['Pekerjaan', ':', data.patient.profession || '-'],
          ['Alamat', ':', data.patient.address],
          ['Diagnosa', ':', data.detail.diagnose.text],
          ['Terapi yang telah diberikan', ':', data.detail.therapy || '-'],
          ['Saran/Anjuran', ':', data.detail.suggestions || '-'],
          [''],
          [''],
        ],
        startY: addLine(12),
        columnStyles: {
          0: { cellWidth: 1.8, cellPadding: { bottom: 0.05 } },
          1: { cellWidth: 0.15, cellPadding: { bottom: 0.05 } },
          2: { cellWidth: 4.5, cellPadding: { bottom: 0.05 } },
        },
        margin: {
          left: marginX,
          top: marginY,
        },
        styles: {
          fontSize: 10,
        },
        theme: 'plain',
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
      });

      if (lastDidDrawPageData.cursor.y <= 9.1) {
        doc.text(
          `Atas bantuannya, diucapkan terima kasih`,
          marginX,
          doc.lastAutoTable.finalY,
        );
      }
      let finalY = doc.lastAutoTable.finalY;

      if (lastDidDrawPageData.cursor.y >= 9.1) {
        doc.addPage();
        finalY = 1.5;
      }

      if (lastDidDrawPageData.cursor.y >= 9.1) {
        finalY += 0.3;
        doc.text(`Atas bantuannya, diucapkan terima kasih`, marginX, finalY);
      }

      this.generateQRCode(
        {
          letterName: 'Surat Rujukan',
          doctor: data.detail.doctor.name,
          sip: this.doctorSip || data.detail.doctor.sip,
          doc: doc,
          x: 0.9,
          y: finalY + 0.1,
        },
        QRCode,
        moment,
      );
      doc.autoTable({
        body: [
          [
            `${clientData.clinicRegion}, ${moment(
              data.detail.timestamps.created_at || new Date(),
            )
              .locale('id')
              .format('DD MMMM YYYY')}`,
          ],
          [data.detail.doctor.name],
        ],
        startY: finalY + 0.2,
        columnStyles: {
          0: { cellWidth: 2, cellPadding: { bottom: 0.7 } },
        },
        margin: {
          left: marginX + 4,
        },
        styles: {
          fontSize: 10,
          halign: 'center',
        },
        theme: 'plain',
      });

      if (data.detail.doctor?.sip) {
        const sip = 'SIP : ' + data.detail.doctor.sip;
        var namaLength = data.detail.doctor.name.length * 0.04;
        var nomorLength = data.detail.doctor.sip.length * 0.04;
        var lineLength = Math.max(namaLength, nomorLength);
        doc.setLineWidth(0.001);
        doc.setDrawColor(0, 0, 0);

        if (sip !== 'SIP : ') {
          doc.line(
            6.05 - lineLength,
            finalY + 1.25,
            6 + lineLength,
            finalY + 1.25,
          );
          alignCenter(`${sip}`, undefined, finalY + 1.25 + 0.15, 1.9);
        }
      }

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }

      return doc;
    },
    generateOutpatientLetter(patientData, input) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setProperties({
        title: `Surat Kontrol Rawat Jalan no.RM: ${patientData.rmNumber}`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('SURAT KONTROL RAWAT JALAN', undefined, 1.85);
      const diagnose = input.diagnose.filter(x => x !== '');
      const mappedDiagnose = diagnose.map((arr, index) => {
        return index === 0
          ? [
              'Diagnosa',
              ':',
              diagnose.length === 1 ? arr.text : `${index + 1}. ${arr.text}`,
            ]
          : ['', '', `${index + 1}. ${arr.text}`];
      });

      const therapy = input.therapies.filter(x => x !== '');
      const mappedTherapy = therapy.map((arr, index) => {
        return index === 0
          ? ['Terapi', ':', `${index + 1}. ${arr}`]
          : ['', '', `${index + 1}. ${arr}`];
      });

      const suggestion = input.suggestions.filter(x => x !== '');
      const mappedSuggestion = suggestion.map((arr, index) => {
        return index === 0
          ? ['Anjuran', ':', `${index + 1}. ${arr}`]
          : ['', '', `${index + 1}. ${arr}`];
      });

      let lastDidDrawPageData = null;

      doc.autoTable({
        body: [
          ['Nomor Surat', ':', input.numberLetter],
          [''],
          [
            {
              content: 'Identitas Pasien',
              styles: { fontStyle: 'bold', fontSize: 12 },
            },
            '',
            '',
          ],
          ['Nama Pasien', ':', `${patientData.meta.name}`],
          ['Nomor Rekam Medis', ':', `${patientData.rmNumber}`],
          [
            'Tanggal Lahir',
            ':',
            `${
              moment(patientData.meta.birthDate, 'DD/MM/YYYY', true).isValid()
                ? moment(patientData.meta.birthDate)
                    .locale('id')
                    .format('LL')
                : patientData.meta.birthDate
            }`,
          ],
          ['Alamat', ':', `${patientData.meta.address}`],
          [
            {
              content: 'Informasi Kontrol Rawat Jalan',
              colSpan: 3,
              styles: { fontStyle: 'bold', fontSize: 12 },
            },
            '',
            '',
          ],
          [
            'Asal Klinik',
            ':',
            `${patientData.meta.polyName || patientData.meta.unitName}`,
          ],
          ['Diijinkan Pulang', ':', `${input.returnStatus}`],
          ['Nama Dokter', ':', `${input.doctor.doctorName}`],
          [
            'Tanggal Kontrol Kembali',
            ':',
            `${moment(input.controlDate)
              .locale('id')
              .format('DD MMMM YYYY')}`,
          ],
          ...mappedDiagnose,
          ...mappedTherapy,
          ...mappedSuggestion,
        ],
        startY: addLine(6),
        style: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4.5 },
        },
        didDrawCell: e => {
          lastDidDrawPageData = e;
        },
        margin: {
          left: marginX,
          right: marginX,
          top: 1.7,
        },
        theme: 'plain',
      });

      let lastLine = doc.lastAutoTable.finalY;

      if (lastDidDrawPageData.cursor.y >= 9.1) {
        doc.addPage();
        lastLine = 1.5;
      }

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      this.generateQRCode(
        {
          letterName: 'Surat Kontrol Rawat Jalan',
          doctor: input.doctor.doctorName,
          sip: this.doctorSip || input.doctor.sip,
          doc: doc,
          x: 1,
          y: lastLine + 0.8,
        },
        QRCode,
        moment,
      );
      alignCenter(
        `${this.clientData.clinicRegion},  ${moment(
          input.expiredDate || this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        lastLine + 1,
        2,
      );
      var namaLength = input.doctor.doctorName.length * 0.04;
      var nomorLength =
        this.doctorSip === undefined
          ? (input.doctor.sip.length + 6) * 0.04
          : this.doctorSip.length * 0.04;
      var lineLength = Math.max(namaLength, nomorLength);
      alignCenter(`${input.doctor.doctorName}`, undefined, lastLine + 2, 2);
      doc.setLineWidth(0.001);
      doc.setDrawColor(0, 0, 0);
      doc.line(
        6.2 - lineLength,
        lastLine + 2.05,
        6.1 + lineLength,
        lastLine + 2.05,
      );
      if (this.doctorSip || input.doctor.sip) {
        alignCenter(
          `SIP : ${this.doctorSip || input.doctor.sip}`,
          undefined,
          lastLine + 2.2,
          2,
        );
      }

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }

      return doc;
    },
    generateInpatientLetter(patientData, input) {
      const clientData = this.$store.getters.userLoggedIn.clinic;
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });
      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };
      doc.setProperties({
        title: `Surat Perintah Rawat Inap no.RM: ${patientData.rmNumber}`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('SURAT PERINTAH RAWAT INAP', undefined, 1.85);
      const dpjp = input.dpjp.filter(x => x !== '');
      const diagnose = input.diagnosis_in.filter(x => x !== '');

      const mappedDPJP = dpjp.map((arr, index) => {
        return index === 0
          ? ['Nama DPJP ', ':', `${index + 1}. ${arr}`]
          : ['', '', `${index + 1}. ${arr}`];
      });

      const mappedDiagnose = diagnose.map((arr, index) => {
        return index === 0
          ? [
              'Nama Diagnosa ',
              ':',
              `${index + 1}. ${arr.text || arr.description}`,
            ]
          : ['', '', `${index + 1}. ${arr.text || arr.description}`];
      });

      let lastDidDrawPageData = null;

      doc.autoTable({
        body: [
          [
            'Nomor Surat',
            ':',
            `${this.numberLetter || patientData.numberLetter}`,
          ],
          [],
          ['Nama Pasien', ':', `${patientData.meta.name}`],
          ['Nomor Rekam Medis', ':', `${patientData.rmNumber}`],
          ['Tanggal Lahir', ':', `${patientData.meta.birthDate}`],
          ['Alamat', ':', `${patientData.meta.address}`],
          ['Tipe Penjamin', ':', `${input.insurance_type}`],
          ['Jenis Kasus', ':', `${input.case_type}`],
          ['Jenis Pelayanan', ':', `${input.service_type}`],
          ...mappedDPJP,
          ...mappedDiagnose,
          ['Catatan', ':', `${input.note}`],
        ],
        startY: addLine(6),
        style: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 2 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4.5 },
        },
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        margin: {
          left: marginX,
          right: marginX,
          top: 1.9,
        },
        theme: 'plain',
      });

      let finalY = doc.lastAutoTable.finalY;

      if (lastDidDrawPageData.cursor.y >= 9.1) {
        doc.addPage();
        finalY = 1.5;
      }

      if (lastDidDrawPageData.cursor.y >= 9.1) {
        finalY += 0.3;
      }

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      this.generateQRCode(
        {
          letterName: 'Surat Perintah Rawat Inap',
          doctor: input.dpjp[0],
          sip: this.doctorSip || input.sip,
          doc: doc,
          x: 1,
          y: finalY + 0.7,
        },
        QRCode,
        moment,
      );
      alignCenter(
        `${clientData.clinicRegion},  ${moment(patientData.timestamps)
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        finalY + 1,
        2,
      );
      var namaLength = input.dpjp[0].length * 0.04;
      var nomorLength =
        this.doctorSip === undefined
          ? (input.sip.length + 6) * 0.04
          : (this.doctorSip.length + 6) * 0.04;
      var lineLength = Math.max(namaLength, nomorLength);
      alignCenter(`${input.dpjp[0]}`, undefined, finalY + 2, 2);
      doc.setLineWidth(0.01);
      doc.setDrawColor(0, 0, 0);
      doc.line(
        6.2 - lineLength,
        finalY + 2.05,
        6.1 + lineLength,
        finalY + 2.05,
      );
      if (this.doctorSip || input.sip) {
        alignCenter(
          `SIP : ${this.doctorSip || input.sip}`,
          undefined,
          finalY + 2.2,
          2,
        );
      }

      // letter head
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
          'in',
        );
      }
      return doc;
    },
    generateHealthLetter(patientData, input) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setProperties({
        title: `Surat Keterangan Sehat no.RM: ${patientData.rmNumber}`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      // doc.addImage(logoFix1, 'JPEG', 1.1, 0.9, 1.6, 0.4);
      alignCenter('SURAT KETERANGAN DOKTER', undefined, 1.85);
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text(
        `Nomor Surat     : ${this.numberLetter || patientData.numberLetter}`,
        marginX,
        addLine(6),
      );
      doc.text(
        `Nomor RM      : ${patientData.rmNumber}`,
        doc.internal.pageSize.getWidth() - 1,
        addLine(6),
        { align: 'right' },
      );

      let lastDidDrawPageData = null;
      doc.autoTable({
        body: [
          [
            {
              content:
                'Dengan mengingat sumpah jabatan, saya yang bertandatangan di bawah ini menerangkan bahwa :',
              colSpan: 4,
            },
            '',
            '',
          ],
          [''],
          ['', 'Nama', ':', `${patientData.meta.name}`],
          ['', 'Umur', ':', `${patientData.meta.age} tahun`],
          ['', 'Jenis Kelamin', ':', `${patientData.meta.gender}`],
          ['', 'Alamat', ':', `${patientData.meta.address}`],
          ['', 'Pekerjaan ', ':', `${patientData.meta.profession}`],
          ['', 'Tinggi Badan', ':', `${input.height} cm`],
          ['', 'Berat Badan', ':', `${input.weight} kg`],
          ['', 'Tensimeter', ':', `${input.sistole} / ${input.diastole} mmHg`],
          ['', 'Nadi', ':', `${input.pulse} x/menit`],
          ['', 'Respirasi', ':', `${input.respiration} x/menit`],
          ['', 'Catatan', ':', `${input.notes || '-'}`],
        ],
        startY: addLine(7),
        styles: {
          halign: 'left',
          textColor: 0,
          cellPadding: 0.015,
        },
        columnStyles: {
          0: { cellWidth: 0.5 },
          1: { cellWidth: 1.5 },
          2: { cellWidth: 0.2 },
          3: { cellWidth: 4.2 },
        },
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        margin: {
          left: marginX - 0.05,
          right: marginX,
          top: 1.7,
        },
        theme: 'plain',
      });

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text(
        `Setelah melalui pemeriksaan, dinyatakan`,
        marginX,
        doc.lastAutoTable.finalY + 0.4,
      );
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'bold');
      doc.text(
        input.patient_condition.toUpperCase(),
        marginX + 2.55,
        doc.lastAutoTable.finalY + 0.4,
      );
      doc.setLineWidth(0.02);
      doc.setDrawColor('black');
      doc.line(
        3.55,
        doc.lastAutoTable.finalY + 0.42,
        4.03,
        doc.lastAutoTable.finalY + 0.42,
      );
      doc.setFont('Helvetica', 'normal');
      doc.text(
        'Demikian surat keterangan ini dibuat untuk keperluan :',
        marginX,
        doc.lastAutoTable.finalY + 0.6,
      );

      doc.autoTable({
        body: [[input.purpose_letter.toUpperCase()]],
        startY: doc.lastAutoTable.finalY + 0.8,
        styles: {
          halign: 'center',
          textColor: 0,
          cellPadding: 0.015,
          fontStyle: 'bold',
        },
        columnStyles: {
          3: { cellWidth: 4.2 },
        },
        margin: {
          left: marginX - 0.05,
          right: marginX,
          top: 1.7,
        },
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        theme: 'plain',
      });

      var namaLength = input.doctor.doctorName.length * 0.04;
      var nomorLength =
        this.doctorSip === undefined
          ? (input.doctor.sip.length + 6) * 0.04
          : this.doctorSip.length * 0.04;
      var lineLength = Math.max(namaLength, nomorLength);

      if (lastDidDrawPageData.cursor.y <= 7.7) {
        doc.autoTable({
          body: [],
          startY: doc.lastAutoTable.finalY + 1,
          styles: {
            halign: 'left',
            textColor: 0,
          },
          columnStyles: {
            0: { cellWidth: 1.25 },
            1: { cellWidth: 0.2 },
            2: { cellWidth: 5.5 },
          },
          didDrawCell: e => {
            e.doc.internal.write(0, 'Tw');
          },
          margin: {
            left: marginX - 0.05,
            right: marginX,
          },
          theme: 'plain',
        });

        doc.setFont('Helvetica', 'normal');
        this.generateQRCode(
          {
            letterName: 'Surat Keterangan Dokter',
            doctor: input.doctor.doctorName,
            sip: this.doctorSip || input.doctor.sip,
            doc: doc,
            x: 0.9,
            y: doc.lastAutoTable.finalY + -0.5,
          },
          QRCode,
          moment,
        );
        alignCenter(
          `${this.clientData.clinicRegion}, ${moment(
            this.timestamps || input.timestamps,
          )
            .locale('id')
            .format('DD MMMM YYYY')}`,
          undefined,
          doc.lastAutoTable.finalY + -0.2,
          2,
        );
        // alignCenter(
        //   `Dokter Pemeriksa`,
        //   undefined,
        //   doc.lastAutoTable.finalY + 0.7,
        //   2,
        // );
        alignCenter(
          `${input.doctor.doctorName}`,
          undefined,
          doc.lastAutoTable.finalY + 0.9,
          2,
        );
        doc.setLineWidth(0.01);
        doc.setDrawColor(0, 0, 0);
        doc.line(
          6.2 - lineLength,
          doc.lastAutoTable.finalY + 0.95,
          6.1 + lineLength,
          doc.lastAutoTable.finalY + 0.95,
        );
        if (this.doctorSip || input.doctor.sip) {
          alignCenter(
            `SIP : ${this.doctorSip || input.doctor.sip}`,
            undefined,
            doc.lastAutoTable.finalY + 1.1,
            2,
          );
        }
      }
      let finalY = doc.lastAutoTable.finalY;

      if (lastDidDrawPageData.cursor.y >= 7.7) {
        doc.addPage();
        finalY = 0.5;
      }

      if (lastDidDrawPageData.cursor.y >= 7.7) {
        doc.autoTable({
          body: [],
          startY: finalY + 1,
          styles: {
            halign: 'left',
            textColor: 0,
          },
          columnStyles: {
            0: { cellWidth: 1.25 },
            1: { cellWidth: 0.2 },
            2: { cellWidth: 5.5 },
          },
          didDrawCell: e => {
            e.doc.internal.write(0, 'Tw');
          },
          margin: {
            left: marginX - 0.05,
            right: marginX,
          },
          theme: 'plain',
        });

        doc.setFont('Helvetica', 'normal');
        this.generateQRCode(
          {
            letterName: 'Surat Keterangan Dokter',
            doctor: input.doctor.doctorName,
            sip: this.doctorSip || input.doctor.sip,
            doc: doc,
            x: 0.9,
            y: finalY + 1.2,
          },
          QRCode,
          moment,
        );
        alignCenter(
          `${this.clientData.clinicRegion}, ${moment(
            this.timestamps || input.timestamps,
          )
            .locale('id')
            .format('DD MMMM YYYY')}`,
          undefined,
          finalY + 1.4,
          2,
        );
        // alignCenter(`Dokter Pemeriksa`, undefined, finalY + 1.6, 2);
        alignCenter(`${input.doctor.doctorName}`, undefined, finalY + 2.6, 2);
        doc.setLineWidth(0.01);
        doc.setDrawColor(0, 0, 0);
        doc.line(
          6.2 - lineLength,
          finalY + 2.65,
          6.1 + lineLength,
          finalY + 2.65,
        );
        if (this.doctorSip || input.doctor.sip) {
          alignCenter(
            `SIP : ${this.doctorSip || input.doctor.sip}`,
            undefined,
            finalY + 2.8,
            2,
          );
        }
      }

      // Letter Head
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }
      return doc;
    },
    generateSickLetter(patientData, input) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;
      const justifyWidth = doc.internal.pageSize.getWidth() - 2;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setProperties({
        title: `Surat Keterangan Sakit no.RM: ${patientData.rmNumber}`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('SURAT KETERANGAN SAKIT', undefined, 1.85);
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text(
        `Nomor Surat          : ${this.numberLetter ||
          patientData.numberLetter}`,
        marginX,
        addLine(6),
      );
      doc.text(
        `Nomor RM       : ${patientData.rmNumber}`,
        doc.internal.pageSize.getWidth() - 1.7,
        addLine(6),
        { align: 'right' },
      );

      let lastDidDrawPageData = null;
      doc.autoTable({
        body: [
          [
            {
              content:
                'Dengan mengingat sumpah jabatan, saya yang bertandatangan di bawah ini menerangkan bahwa :',
              colSpan: 4,
            },
            '',
            '',
          ],
          [''],
          ['', 'Nama', ':', `${patientData.meta.name}`],
          ['', 'Umur', ':', `${patientData.meta.age} tahun`],
          ['', 'Jenis Kelamin', ':', `${patientData.meta.gender}`],
          ['', 'Alamat', ':', `${patientData.meta.address}`],
          ['', 'Pekerjaan ', ':', `${patientData.meta.profession || '-'}`],
          ['', 'Tinggi Badan', ':', input.height ? `${input.height} cm` : '-'],
          ['', 'Berat Badan', ':', input.weight ? `${input.weight} kg` : '-'],
          [
            '',
            'Tensimeter',
            ':',
            input.sistole && input.diastole
              ? `${input.sistole} / ${input.diastole} mmHg`
              : '-',
          ],
          ['', 'Nadi', ':', input.pulse ? `${input.pulse} x/menit` : '-'],
          [
            '',
            'Respirasi',
            ':',
            input.respiration ? `${input.respiration} x/menit` : '-',
          ],
          ['', 'Catatan', ':', input.notes ? `${input.notes || '-'}` : '-'],
        ],
        startY: addLine(7),
        styles: {
          halign: 'left',
          textColor: 0,
          cellPadding: 0.015,
        },
        columnStyles: {
          0: { cellWidth: 0.5 },
          1: { cellWidth: 1.5 },
          2: { cellWidth: 0.2 },
          3: { cellWidth: 4.2 },
        },
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        margin: {
          left: marginX - 0.05,
          right: marginX,
          top: 1.7,
        },
        theme: 'plain',
      });

      const parseDate = date => {
        return moment(date, 'LL', true)
          .locale('id')
          .isValid()
          ? moment(moment(date, 'D MMMM YYYY').format('YYYY-MM-DD'))
          : moment(date);
      };

      const a = parseDate(input.date_end);
      const b = parseDate(input.date_start);
      const days = a.diff(b, 'days');

      if (lastDidDrawPageData.cursor.y <= 8.6) {
        doc.text(
          `Setelah dilakukan pemeriksaan Kesehatan maka dinyatakan SAKIT dan dibutuhkan istirahat selama ${days +
            1} hari, mulai tanggal ${parseDate(input.date_start)
            .locale('id')
            .format('LL')} s/d ${parseDate(input.date_end)
            .locale('id')
            .format('LL')}.`,
          marginX,
          doc.lastAutoTable.finalY + 0.4,
          { align: 'justify', maxWidth: justifyWidth },
        );

        doc.text(
          'Demikian surat keterangan sakit ini dibuat untuk digunakan sebagaimana mestinya.',
          marginX,
          doc.lastAutoTable.finalY + 0.8,
        );
      }

      let finalY = doc.lastAutoTable.finalY;

      if (lastDidDrawPageData.cursor.y >= 8.6) {
        doc.addPage();
        finalY = 1.5;
      }

      if (lastDidDrawPageData.cursor.y >= 8.6) {
        finalY += 0.1;
        doc.text(
          `Setelah dilakukan pemeriksaan Kesehatan maka dinyatakan SAKIT dan dibutuhkan istirahat selama ${days +
            1} hari, mulai tanggal ${parseDate(input.date_start)
            .locale('id')
            .format('LL')} s/d ${parseDate(input.date_end)
            .locale('id')
            .format('LL')}.`,
          marginX,
          finalY + 0.4,
          { align: 'justify', maxWidth: justifyWidth },
        );

        doc.text(
          'Demikian surat keterangan sakit ini dibuat untuk digunakan sebagaimana mestinya.',
          marginX,
          finalY + 0.8,
        );
      }

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      this.generateQRCode(
        {
          letterName: 'Surat Keterangan Sakit',
          doctor: input.doctor.doctorName,
          sip: this.doctorSip || input.doctor.sip,
          doc: doc,
          x: 0.9,
          y: finalY + 1,
        },
        QRCode,
        moment,
      );

      alignCenter(
        `${this.clientData.clinicRegion}, ${moment(
          input.created_at || this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        finalY + 1.4,
        2,
      );
      var namaLength = input.doctor.doctorName.length * 0.04;
      var nomorLength =
        this.doctorSip === undefined
          ? (input.doctor.sip.length + 6) * 0.04
          : this.doctorSip.length * 0.04;
      var lineLength = Math.max(namaLength, nomorLength);
      alignCenter(`${input.doctor.doctorName}`, undefined, finalY + 2.2, 2);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.01);
      doc.line(
        6.2 - lineLength,
        finalY + 2.25,
        6.1 + lineLength,
        finalY + 2.25,
      );
      if (this.doctorSip || input.doctor.sip) {
        alignCenter(
          `SIP : ${this.doctorSip || input.doctor.sip}`,
          undefined,
          finalY + 2.4,
          2,
        );
      }

      // Letter Head
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }
      return doc;
    },
    generateDeathLetter(patientData, input) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setProperties({
        title: `Surat Keterangan Kematian no.RM: ${patientData.rmNumber}`,
      });

      // letter head
      printMixin.methods.letterHead(
        doc,
        this.$store.getters.userLoggedIn.clinic,
      );

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('SURAT KETERANGAN KEMATIAN', undefined, 1.85);
      doc.autoTable({
        body: [
          [
            'Nomor Surat',
            ':',
            `${this.numberLetter || patientData.numberLetter}`,
          ],
          [],
          [
            {
              content:
                'Yang bertandatangan di bawah ini menerangkan dengan sesungguhnya bahwa :',
              colSpan: 3,
            },
            '',
            '',
          ],
          ['Nama', ':', `${patientData.meta.name}`],
          ['Nomor Rekam Medis', ':', `${patientData.rmNumber}`],
          ['Jenis Kelamin', ':', `${patientData.meta.gender}`],
          ['Tanggal Lahir', ':', `${patientData.meta.birthDate}`],
          ['Alamat', ':', `${patientData.meta.address}`],
          ['Nomor KTP', '', `${patientData.meta.identityNumber}`],
          [{ content: 'Telah meninggal dunia pada :', colSpan: 2 }, '', ''],
          [
            'Hari',
            ':',
            `${
              this.resource.days === undefined
                ? input.day
                : this.resource.days[new Date(input.date).getDay()]
            }`,
          ],
          [
            'Tanggal',
            ':',
            `${
              moment(input.date, 'LL', true)
                .locale('id')
                .isValid()
                ? input.date
                : moment(input.date)
                    .locale('id')
                    .format('LL')
            }`,
          ],
          ['Jam', ':', `${input.time} WIB`],
        ],
        startY: addLine(6),
        style: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4.5 },
        },
        didDrawCell: e => {
          e.doc.internal.write(0, 'Tw');
        },
        margin: {
          left: marginX,
          right: marginX,
        },
        theme: 'plain',
      });
      const lastLine = doc.autoTable.previous.finalY;
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text(
        `Di Klinik ${this.$store.getters.userLoggedIn.clinic.name}`,
        marginX,
        lastLine + 0.2,
      );
      doc.text(
        `Penyakit yang diderita ${
          patientData.meta.gender ? `"almarhum"` : `"almarhumah"`
        } tergolong ${input.disease_type.toLowerCase()}.`,
        marginX,
        lastLine + 0.4,
      );

      alignCenter(
        `${this.clientData.clinicRegion},  ${moment(
          input.createdAt || this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        lastLine + 1.2,
        2,
      );
      var namaLength = input.doctor.doctorName.length * 0.04;
      var nomorLength =
        this.doctorSip === undefined
          ? (input.doctor.sip.length + 6) * 0.04
          : this.doctorSip.length * 0.04;
      var lineLength = Math.max(namaLength, nomorLength);
      alignCenter(`${input.doctor.doctorName}`, undefined, lastLine + 2.2, 2);
      doc.setLineWidth(0.001);
      doc.setDrawColor(0, 0, 0);
      doc.line(
        6.2 - lineLength,
        lastLine + 2.25,
        6.1 + lineLength,
        lastLine + 2.25,
      );
      if (this.doctorSip || input.doctor.sip) {
        alignCenter(
          `SIP : ${this.doctorSip || input.doctor.sip}`,
          undefined,
          lastLine + 2.4,
          2,
        );
      }

      return doc;
    },
    generateDOALetter(patientData, input) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setProperties({
        title: `Surat Keterangan Kematian DOA no.RM: ${patientData.rmNumber}`,
      });

      // letter head
      printMixin.methods.letterHead(
        doc,
        this.$store.getters.userLoggedIn.clinic,
      );

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('SURAT KETERANGAN KEMATIAN', undefined, 1.85);
      alignCenter('Death On Arrival (DOA)', undefined, 2.1);

      doc.autoTable({
        body: [
          [
            'Nomor Surat',
            ':',
            `${this.numberLetter || patientData.numberLetter}`,
          ],
          [],
          [
            {
              content: 'Yang bertandatangan di bawah ini :',
              colSpan: 3,
            },
            '',
            '',
          ],
          ['Nama', ':', `${patientData.meta.doctor}`],
          [
            'Jabatan',
            ':',
            `${
              patientData.meta.role?.[0] === undefined
                ? '-'
                : patientData.meta.role?.[0]?.charAt(0).toUpperCase()
            }${
              patientData.meta.role?.[0] === undefined
                ? ''
                : patientData.meta.role?.[0]?.slice(1)
            }`,
          ],
          [''],
          [
            {
              content: 'Telah memeriksa dan menyatakan :',
              colSpan: 3,
            },
            '',
            '',
          ],
          ['Nama', ':', `${patientData.meta.name}`],
          ['Nomor Rekam Medis', ':', `${patientData.rmNumber}`],
          ['Jenis Kelamin', ':', `${patientData.meta.gender}`],
          ['Umur', ':', `${patientData.meta.age} tahun`],
          ['Alamat', ':', `${patientData.meta.address}`],
          ['Nomor KTP', '', `${patientData.meta.identityNumber}`],
          [
            {
              content: `Tiba di Klinik ${this.clientData.name} :`,
              colSpan: 3,
            },
            '',
            '',
          ],
          [
            'Hari',
            ':',
            `${
              this.resource.days === undefined
                ? input.day
                : this.resource.days[new Date(input.date).getDay()]
            }`,
          ],
          [
            'Tanggal',
            ':',
            `${
              moment(input.date, 'LL', true)
                .locale('id')
                .isValid()
                ? input.date
                : moment(input.date)
                    .locale('id')
                    .format('LL')
            }`,
          ],
          ['Jam', ':', `${input.time}`],
        ],
        startY: addLine(7),
        style: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4.5 },
        },
        didDrawCell: e => {
          e.doc.internal.write(0, 'Tw');
        },
        margin: {
          left: marginX,
          right: doc.internal.pageSize.getWidth() / 2,
        },
        theme: 'plain',
      });
      const lastLine = doc.autoTable.previous.finalY;
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text(
        'Sudah dalam keadaan meninggal dunia (Death On Arrival)',
        marginX,
        lastLine + 0.2,
      );
      doc.text(
        'Demikian surat keterangan ini agar dapat dipergunakan sebagaimana mestinya.',
        marginX,
        lastLine + 0.4,
      );

      alignCenter(
        `${this.clientData.clinicRegion},  ${moment(
          input.createdAt || this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        lastLine + 1.2,
        2,
      );
      var namaLength = input.doctor.doctorName.length * 0.04;
      var nomorLength =
        this.doctorSip === undefined
          ? (input.doctor.sip.length + 6) * 0.04
          : this.doctorSip.length * 0.04;
      var lineLength = Math.max(namaLength, nomorLength);
      alignCenter(`${input.doctor.doctorName}`, undefined, lastLine + 2.2, 2);
      doc.setLineWidth(0.001);
      doc.setDrawColor(0, 0, 0);
      doc.line(
        6.2 - lineLength,
        lastLine + 2.25,
        6.1 + lineLength,
        lastLine + 2.25,
      );
      if (this.doctorSip || input.doctor.sip) {
        alignCenter(
          `SIP : ${this.doctorSip || input.doctor.sip}`,
          undefined,
          lastLine + 2.4,
          2,
        );
      }
      return doc;
    },
  },
};
