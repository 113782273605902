import { getStaff } from '@/fetchApi/laboratory';
import getActionByEmr from '@/fetchApi/Service/Documents/getActionByEmr';
import addSurgicalSafetyChecklistData from '@/fetchApi/Service/Documents/addSurgicalSafetyChecklist';
import getSurgicalSafetyChecklistById from '@/fetchApi/Service/Documents/getSurgicalSafetyChecklistById';
import { getLastCheckupData } from '@/fetchApi/EMR';

const moment = require('moment-timezone');

const state = {
  counterDoctor: 0,
  counterStaff: 0,
  counterStaffFill: 0,
  isDetail: false,
  isEmr: false,
  isEdit: false,
  idSsc: '',
  snackbar: {
    edit: false,
    add: false,
  },
  resource: {
    doctor: [],
    staff: [],
    actions: [],
    risk: [
      'Kesulitan Jalan Nafas dan Risiko Aspirasi',
      'Risiko Pendarahan > 500 cc pada Dewasa dan Anak 7 cc/kg BB',
      'Penyakit Infeksius',
    ],
  },
  loading: {
    doctor: false,
    data: false,
    dataById: false,
  },
  signIn: {
    action: '',
    time: {
      date: moment().format('DD-MM-YYYY'),
      time: '',
    },
    staffFill: '',
    doctor: '',
    staff: '',
    risk: [],
    allergy: [],
    isHasRisk: null,
    dataConfirmation: null,
    isAnestheticDeviceComplitelyChecked: null,
    isOperatingAreaMarked: null,
  },
  timeOut: {
    time: {
      date: moment().format('DD-MM-YYYY'),
      time: '',
    },
    staffFill: '',
    doctor: '',
    staff: '',
    introduceTeamMemberConfirmation: '',
    incisionDataConfirmation: '',
    isAntibioticProphylaxisHasGiven: '',
    criticalStep: {
      check: null,
      description: '',
    },
    handlingDuration: {
      check: null,
      description: '',
    },
    bleedingHandleDuration: {
      check: null,
      description: '',
    },
    isPatientNeedSpecialAttention: null,
    isSterilizationConfirmed: null,
    isProblematicTool: null,
  },
  signOut: {
    time: {
      date: moment().format('DD-MM-YYYY'),
      time: '',
    },
    staffFill: '',
    doctor: '',
    staff: '',
    procedureActionName: null,
    instrument: null,
    speciment: null,
    isSpecimentLabeled: null,
    isAnyDifficulties: null,
    isSpecialAttention: null,
  },
  initialState: {
    signIn: {
      action: '',
      time: {
        date: moment().format('DD-MM-YYYY'),
        time: '',
      },
      staffFill: '',
      doctor: '',
      staff: '',
      risk: [],
      allergy: [],
      isHasRisk: null,
      dataConfirmation: null,
      isAnestheticDeviceComplitelyChecked: null,
      isOperatingAreaMarked: null,
    },
    timeOut: {
      time: {
        date: moment().format('DD-MM-YYYY'),
        time: '',
      },
      staffFill: '',
      doctor: '',
      staff: '',
      introduceTeamMemberConfirmation: '',
      incisionDataConfirmation: '',
      isAntibioticProphylaxisHasGiven: '',
      criticalStep: {
        check: null,
        description: '',
      },
      handlingDuration: {
        check: null,
        description: '',
      },
      bleedingHandleDuration: {
        check: null,
        description: '',
      },
      isPatientNeedSpecialAttention: null,
      isSterilizationConfirmed: null,
      isProblematicTool: null,
    },
    signOut: {
      time: {
        date: moment().format('DD-MM-YYYY'),
        time: '',
      },
      staffFill: '',
      doctor: '',
      staff: '',
      procedureActionName: null,
      instrument: null,
      speciment: null,
      isSpecimentLabeled: null,
      isAnyDifficulties: null,
      isSpecialAttention: null,
    },
  },
};

const getters = {
  getResource: state => state.resource,
  getSnackbar: state => state.snackbar,
  getIsDetail: state => state.isDetail,
  getIsEdit: state => state.isEdit,
  getIsEmr: state => state.isEmr,
  getSignIn: state => state.signIn,
  getTimeOut: state => state.timeOut,
  getSignOut: state => state.signOut,
  getLoading: state => state.loading,
  getInitialState: state => state.initialState,
  getIdSsc: state => state.idSsc,
};

const mutations = {
  clearSurgicalSafetyChecklistState() {
    Object.assign(state, {
      counterDoctor: 0,
      counterStaff: 0,
      counterStaffFill: 0,
      isDetail: false,
      isEmr: false,
      isEdit: false,
      idSsc: '',
      resource: {
        doctor: [],
        staff: [],
        actions: [],
        risk: [
          'Kesulitan Jalan Nafas dan Risiko Aspirasi',
          'Risiko Pendarahan > 500 cc pada Dewasa dan Anak 7 cc/kg BB',
          'Penyakit Infeksius',
        ],
      },
      loading: {
        doctor: false,
        data: false,
        dataById: false,
      },
      signIn: {
        action: '',
        time: {
          date: moment().format('DD-MM-YYYY'),
          time: '',
        },
        staffFill: '',
        doctor: '',
        staff: '',
        risk: [],
        allergy: [],
        isHasRisk: null,
        dataConfirmation: null,
        isAnestheticDeviceComplitelyChecked: null,
        isOperatingAreaMarked: null,
      },
      timeOut: {
        time: {
          date: moment().format('DD-MM-YYYY'),
          time: '',
        },
        staffFill: '',
        doctor: '',
        staff: '',
        introduceTeamMemberConfirmation: '',
        incisionDataConfirmation: '',
        isAntibioticProphylaxisHasGiven: '',
        criticalStep: {
          check: null,
          description: '',
        },
        handlingDuration: {
          check: null,
          description: '',
        },
        bleedingHandleDuration: {
          check: null,
          description: '',
        },
        isPatientNeedSpecialAttention: null,
        isSterilizationConfirmed: null,
        isProblematicTool: null,
      },
      signOut: {
        time: {
          date: moment().format('DD-MM-YYYY'),
          time: '',
        },
        staffFill: '',
        doctor: '',
        staff: '',
        procedureActionName: null,
        instrument: null,
        speciment: null,
        isSpecimentLabeled: null,
        isAnyDifficulties: null,
        isSpecialAttention: null,
      },
      initialState: {
        signIn: {
          action: '',
          time: {
            date: moment().format('DD-MM-YYYY'),
            time: '',
          },
          staffFill: '',
          doctor: '',
          staff: '',
          risk: [],
          allergy: [],
          isHasRisk: null,
          dataConfirmation: null,
          isAnestheticDeviceComplitelyChecked: null,
          isOperatingAreaMarked: null,
        },
        timeOut: {
          time: {
            date: moment().format('DD-MM-YYYY'),
            time: '',
          },
          staffFill: '',
          doctor: '',
          staff: '',
          introduceTeamMemberConfirmation: '',
          incisionDataConfirmation: '',
          isAntibioticProphylaxisHasGiven: '',
          criticalStep: {
            check: null,
            description: '',
          },
          handlingDuration: {
            check: null,
            description: '',
          },
          bleedingHandleDuration: {
            check: null,
            description: '',
          },
          isPatientNeedSpecialAttention: null,
          isSterilizationConfirmed: null,
          isProblematicTool: null,
        },
        signOut: {
          time: {
            date: moment().format('DD-MM-YYYY'),
            time: '',
          },
          staffFill: '',
          doctor: '',
          staff: '',
          procedureActionName: null,
          instrument: null,
          speciment: null,
          isSpecimentLabeled: null,
          isAnyDifficulties: null,
          isSpecialAttention: null,
        },
      },
    });
  },
  setLoading(state, payload) {
    const { label, value } = payload;
    state.loading[label] = value;
  },
  setSnackbar(state, payload) {
    const { label, value } = payload;
    state.snackbar[label] = value;
  },
  setIsEdit(state, payload) {
    state.isEdit = payload;
  },
  setIsDetail(state, payload) {
    state.isDetail = payload;
  },
  setIsEmr(state, payload) {
    state.isEmr = payload;
  },
  setCounterDoctor(state) {
    state.counterDoctor++;
  },
  setCounterStaffFill(state) {
    state.counterStaffFill++;
  },
  setCounterStaff(state) {
    state.counterStaff++;
  },
  setResource(state, payload) {
    const { label, value } = payload;
    state.resource[label] = value;
  },
  setSignInPhase(state, payload) {
    const { label, value } = payload;
    state.signIn[label] = value;
  },
  setTimeOutPhase(state, payload) {
    const { label, value } = payload;
    state.timeOut[label] = value;
  },
  setSignOutPhase(state, payload) {
    const { label, value } = payload;
    state.signOut[label] = value;
  },
  setInitialState(state, payload) {
    state.initialState = payload;
  },
  setIdSsc(state, payload) {
    state.idSsc = payload;
  },
};

const actions = {
  async resolveGetPatientAllergy({ commit, state }, idEmr) {
    try {
      const response = await getLastCheckupData(idEmr);
      const { allergy_history } = response.data.data.subjective;
      commit('setSignInPhase', {
        label: 'allergy',
        value: allergy_history || [],
      });
      commit('setInitialState', {
        ...state.initialState,
        signIn: {
          ...state.initialState.signIn,
          allergy: allergy_history || [],
        },
      });
    } catch {
      commit('setSignInPhase', {
        label: 'allergy',
        value: [],
      });
    }
  },
  async resolveGetStaff({ commit }) {
    try {
      commit('setLoading', { label: 'doctor', value: true });
      commit('setResource', { label: 'doctor', value: [] });
      commit('setResource', { label: 'staff', value: [] });
      const staff = await getStaff();
      commit('setResource', {
        label: 'doctor',
        value: staff
          .filter(
            item =>
              item.role?.status &&
              item.role?.role.some(item => item.toLowerCase() === 'dokter'),
          )
          .map(i => {
            return {
              name: i.detail.name,
              id: i._id,
            };
          }),
      });
      commit('setResource', {
        label: 'staff',
        value: staff
          .filter(
            item =>
              item.role?.status &&
              item.role?.role.some(item => item.toLowerCase() === 'perawat'),
          )
          .map(i => {
            return {
              name: i.detail.name,
              id: i._id,
            };
          }),
      });
    } catch {
      commit('setResource', { label: 'doctor', value: [] });
      commit('setResource', { label: 'staff', value: [] });
    } finally {
      commit('setLoading', { label: 'doctor', value: false });
    }
  },
  async resolveGetActionByEmr({ commit }, idEmr) {
    try {
      const response = await getActionByEmr(idEmr);
      const actions = response.data.data.map(item => {
        return {
          text: `${moment(item.created_at).format('DD-MM-YYYY')}, ${moment(
            item.created_at,
          ).format('HH:mm')} (${item.name})`,
          name: item.name,
          date: moment(item.created_at).format('DD-MM-YYYY'),
          time: moment(item.created_at).format('HH:mm'),
          doctor: {
            name: item.staff_executor?.detail?.[0]?.name,
            id: item.staff_executor?._id,
          },
          idInformedConsent: item.id_inform_consent,
          id: item.id,
        };
      });
      commit('setResource', { label: 'actions', value: actions });
    } catch {
      commit('setResource', { label: 'actions', value: [] });
    }
  },
  onChangeAction({ commit, state }) {
    commit('setSignInPhase', {
      label: 'doctor',
      value: state.signIn?.action?.doctor?.name
        ? state.signIn?.action?.doctor
        : '',
    });
    commit('setTimeOutPhase', {
      label: 'doctor',
      value: state.signIn?.action?.doctor?.name
        ? state.signIn?.action?.doctor
        : '',
    });
    commit('setSignOutPhase', {
      label: 'doctor',
      value: state.signIn?.action?.doctor?.name
        ? state.signIn?.action?.doctor
        : '',
    });
  },
  onChangeSignInDoctor({ commit, state }) {
    if (state.counterDoctor < 1) {
      commit('setTimeOutPhase', {
        label: 'doctor',
        value: state.signIn?.doctor,
      });
      commit('setSignOutPhase', {
        label: 'doctor',
        value: state.signIn?.doctor,
      });
    }
    commit('setCounterDoctor');
  },
  onChangeSignInStaffFill({ commit, state }) {
    if (state.counterStaffFill < 1) {
      commit('setTimeOutPhase', {
        label: 'staffFill',
        value: state.signIn?.staffFill,
      });
      commit('setSignOutPhase', {
        label: 'staffFill',
        value: state.signIn?.staffFill,
      });
    }
    commit('setCounterStaffFill');
  },
  onChangeSignInStaff({ commit, state }) {
    if (state.counterStaff < 1) {
      commit('setTimeOutPhase', {
        label: 'staff',
        value: state.signIn?.staff,
      });
      commit('setSignOutPhase', {
        label: 'staff',
        value: state.signIn?.staff,
      });
    }
    commit('setCounterStaff');
  },
  async resolvePostSurgicalSafetyChecklistData(
    { commit, state },
    { idEmr, userLoggedIn },
  ) {
    try {
      const payload = {
        id_emr: idEmr,
        _id: state.idSsc || undefined,
        detail: {
          signInPhase: {
            date: state.signIn.time.date,
            time: state.signIn.time.time,
            doctor: state.signIn.doctor.id,
            staff: state.signIn.staff.id,
            staff_fill: state.signIn.staffFill.id,
            isPatientConfirmed: state.signIn.dataConfirmation,
            isToolCompleted: state.signIn.isAnestheticDeviceComplitelyChecked,
            isOperatingAreaMarked: state.signIn.isOperatingAreaMarked,
            action: {
              id: state.signIn.action.id,
              name: state.signIn.action.name,
              date: state.signIn.action.date,
              time: state.signIn.action.time,
              id_inform_consent:
                state.signIn.action.id_inform_consent ||
                state.signIn.action.idInformedConsent,
            },
            allergy: state.signIn.allergy,
            patientRiskState: state.signIn.isHasRisk
              ? state.signIn.risk
              : ['Tidak Ada Keadaan Berisiko'],
            timestamps: {
              created_by: userLoggedIn,
            },
          },
          timeOutPhase: {
            date: state.timeOut.time.date,
            time: state.timeOut.time.time,
            doctor: state.timeOut.doctor.id,
            staff: state.timeOut.staff.id,
            staff_fill: state.timeOut.staffFill.id,
            isTeamConfirmed: state.timeOut.introduceTeamMemberConfirmation,
            isPatientDataConfirmed: state.timeOut.incisionDataConfirmation,
            isAntibiotic: state.timeOut.isAntibioticProphylaxisHasGiven,
            stepCritical: {
              check: state.timeOut.criticalStep.check,
              description: state.timeOut.criticalStep.description,
            },
            duration: {
              check: state.timeOut.handlingDuration.check,
              description: state.timeOut.handlingDuration.description,
            },
            stepBleeding: {
              check: state.timeOut.bleedingHandleDuration.check,
              description: state.timeOut.bleedingHandleDuration.description,
            },
            isSpecialAttention: state.timeOut.isPatientNeedSpecialAttention,
            isSterileConfirmed: state.timeOut.isSterilizationConfirmed,
            isToolProblem: state.timeOut.isProblematicTool,
            timestamps: {
              created_by: userLoggedIn,
            },
          },
          signOutPhase: {
            date: state.signOut.time.date,
            time: state.signOut.time.time,
            doctor: state.signOut.doctor.id,
            staff: state.signOut.staff.id,
            staff_fill: state.signOut.staffFill.id,
            isProcedureActionConfirmed: state.signOut.procedureActionName,
            isToolsCalculated: state.signOut.instrument,
            isNeedTookSpecimen: state.signOut.speciment,
            isSpecimenLabeled: state.signOut.isSpecimentLabeled,
            isDifficultUsingTool: state.signOut.isAnyDifficulties,
            isSpecialAttention: state.signOut.isSpecialAttention,
            timestamps: {
              created_by: userLoggedIn,
            },
          },
        },
      };
      await addSurgicalSafetyChecklistData(payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setSnackbar', {
        label: state.idSsc ? 'edit' : 'add',
        value: true,
      });
    }
  },
  async resolveGetSurgicalSafetyChecklistById({ commit }, id) {
    try {
      commit('setLoading', { label: 'dataById', value: true });
      commit('setIsDetail', true);
      const response = await getSurgicalSafetyChecklistById(id);
      const {
        signInPhase: signin,
        timeOutPhase: timeout,
        signOutPhase: signout,
      } = response.data.data.detail;
      commit('setIdSsc', response.data.data._id);
      // Mapping sign in phase
      commit('setSignInPhase', {
        label: 'action',
        value: {
          date: signin.action.date,
          id: signin.action.id,
          name: signin.action.name,
          time: signin.action.time,
          id_inform_consent: signin.action.id_inform_consent,
          text: `${signin.action.date}, ${signin.action.time} ${signin.action.name}`,
        },
      });
      commit('setSignInPhase', {
        label: 'time',
        value: {
          date: signin.date,
          time: signin.time,
        },
      });
      commit('setSignInPhase', {
        label: 'staffFill',
        value: {
          id: signin.staff_fill._id,
          name: signin.staff_fill.detail[0].name,
        },
      });
      commit('setSignInPhase', {
        label: 'doctor',
        value: {
          id: signin.doctor._id,
          name: signin.doctor.detail[0].name,
        },
      });
      commit('setSignInPhase', {
        label: 'staff',
        value: {
          id: signin.staff._id,
          name: signin.staff.detail[0].name,
        },
      });
      commit('setSignInPhase', {
        label: 'allergy',
        value: signin.allergy || [],
      });
      commit('setSignInPhase', {
        label: 'isHasRisk',
        value: Array.isArray(signin.patientRiskState)
          ? !signin.patientRiskState?.includes('Tidak Ada Keadaan Berisiko')
          : false,
      });
      commit('setSignInPhase', {
        label: 'risk',
        value: signin.patientRiskState,
      });
      commit('setSignInPhase', {
        label: 'dataConfirmation',
        value: signin.isPatientConfirmed,
      });
      commit('setSignInPhase', {
        label: 'isAnestheticDeviceComplitelyChecked',
        value: signin.isToolCompleted,
      });
      commit('setSignInPhase', {
        label: 'isOperatingAreaMarked',
        value: signin.isOperatingAreaMarked,
      });

      // Mapping time out phase
      commit('setTimeOutPhase', {
        label: 'time',
        value: {
          date: timeout.date,
          time: timeout.time,
        },
      });
      commit('setTimeOutPhase', {
        label: 'staffFill',
        value: {
          id: timeout.staff_fill._id,
          name: timeout.staff_fill.detail[0].name,
        },
      });
      commit('setTimeOutPhase', {
        label: 'doctor',
        value: {
          id: timeout.doctor._id,
          name: timeout.doctor.detail[0].name,
        },
      });
      commit('setTimeOutPhase', {
        label: 'staff',
        value: {
          id: timeout.staff._id,
          name: timeout.staff.detail[0].name,
        },
      });
      commit('setTimeOutPhase', {
        label: 'introduceTeamMemberConfirmation',
        value: timeout.isTeamConfirmed,
      });
      commit('setTimeOutPhase', {
        label: 'incisionDataConfirmation',
        value: timeout.isPatientDataConfirmed,
      });
      commit('setTimeOutPhase', {
        label: 'isAntibioticProphylaxisHasGiven',
        value: timeout.isAntibiotic,
      });
      commit('setTimeOutPhase', {
        label: 'isPatientNeedSpecialAttention',
        value: timeout.isSpecialAttention,
      });
      commit('setTimeOutPhase', {
        label: 'isSterilizationConfirmed',
        value: timeout.isSterileConfirmed,
      });
      commit('setTimeOutPhase', {
        label: 'isProblematicTool',
        value: timeout.isToolProblem,
      });
      commit('setTimeOutPhase', {
        label: 'handlingDuration',
        value: {
          check: timeout.duration.check,
          description: timeout.duration.description,
        },
      });
      commit('setTimeOutPhase', {
        label: 'bleedingHandleDuration',
        value: {
          check: timeout.stepBleeding.check,
          description: timeout.stepBleeding.description,
        },
      });
      commit('setTimeOutPhase', {
        label: 'criticalStep',
        value: {
          check: timeout.stepCritical.check,
          description: timeout.stepCritical.description,
        },
      });

      // Mapping sign out phase
      commit('setSignOutPhase', {
        label: 'time',
        value: {
          date: signout.date,
          time: signout.time,
        },
      });
      commit('setSignOutPhase', {
        label: 'staffFill',
        value: {
          id: signout.staff_fill._id,
          name: signout.staff_fill.detail[0].name,
        },
      });
      commit('setSignOutPhase', {
        label: 'doctor',
        value: {
          id: signout.doctor._id,
          name: signout.doctor.detail[0].name,
        },
      });
      commit('setSignOutPhase', {
        label: 'staff',
        value: {
          id: signout.staff._id,
          name: signout.staff.detail[0].name,
        },
      });
      commit('setSignOutPhase', {
        label: 'procedureActionName',
        value: signout.isProcedureActionConfirmed,
      });
      commit('setSignOutPhase', {
        label: 'instrument',
        value: signout.isToolsCalculated,
      });
      commit('setSignOutPhase', {
        label: 'speciment',
        value: signout.isNeedTookSpecimen,
      });
      commit('setSignOutPhase', {
        label: 'isSpecimentLabeled',
        value: signout.isSpecimenLabeled,
      });
      commit('setSignOutPhase', {
        label: 'isSpecialAttention',
        value: signout.isSpecialAttention,
      });
      commit('setSignOutPhase', {
        label: 'isAnyDifficulties',
        value: signout.isDifficultUsingTool,
      });

      // Mapping for initial state
      commit('setInitialState', {
        signIn: {
          action: {
            date: signin.action.date,
            id: signin.action.id,
            name: signin.action.name,
            time: signin.action.time,
            id_inform_consent: signin.action.id_inform_consent,
            text: `${signin.action.date}, ${signin.action.time} ${signin.action.name}`,
          },
          time: {
            date: signin.date,
            time: signin.time,
          },
          doctor: {
            id: signin.doctor._id,
            name: signin.doctor.detail[0].name,
          },
          staff: {
            id: signin.staff._id,
            name: signin.staff.detail[0].name,
          },
          staffFill: {
            id: signin.staff_fill._id,
            name: signin.staff_fill.detail[0].name,
          },
          allergy: signin.allergy || [],
          risk: signin.patientRiskState,
          isHasRisk: Array.isArray(signin.patientRiskState)
            ? !signin.patientRiskState?.includes('Tidak Ada Keadaan Berisiko')
            : false,
          dataConfirmation: signin.isPatientConfirmed,
          isAnestheticDeviceComplitelyChecked: signin.isToolCompleted,
          isOperatingAreaMarked: signin.isOperatingAreaMarked,
        },
        timeOut: {
          time: {
            date: timeout.date,
            time: timeout.time,
          },
          doctor: {
            id: timeout.doctor._id,
            name: timeout.doctor.detail[0].name,
          },
          staff: {
            id: timeout.staff._id,
            name: timeout.staff.detail[0].name,
          },
          staffFill: {
            id: timeout.staff_fill._id,
            name: timeout.staff_fill.detail[0].name,
          },
          introduceTeamMemberConfirmation: timeout.isTeamConfirmed,
          incisionDataConfirmation: timeout.isPatientDataConfirmed,
          isAntibioticProphylaxisHasGiven: timeout.isAntibiotic,
          criticalStep: {
            check: timeout.stepCritical.check,
            description: timeout.stepCritical.description,
          },
          handlingDuration: {
            check: timeout.duration.check,
            description: timeout.duration.description,
          },
          bleedingHandleDuration: {
            check: timeout.stepBleeding.check,
            description: timeout.stepBleeding.description,
          },
          isPatientNeedSpecialAttention: timeout.isSpecialAttention,
          isSterilizationConfirmed: timeout.isSterileConfirmed,
          isProblematicTool: timeout.isToolProblem,
        },
        signOut: {
          time: {
            date: signout.date,
            time: signout.time,
          },
          doctor: {
            id: signout.doctor._id,
            name: signout.doctor.detail[0].name,
          },
          staff: {
            id: signout.staff._id,
            name: signout.staff.detail[0].name,
          },
          staffFill: {
            id: signout.staff_fill._id,
            name: signout.staff_fill.detail[0].name,
          },
          procedureActionName: signout.isProcedureActionConfirmed,
          instrument: signout.isToolsCalculated,
          speciment: signout.isNeedTookSpecimen,
          isSpecimentLabeled: signout.isSpecimenLabeled,
          isAnyDifficulties: signout.isDifficultUsingTool,
          isSpecialAttention: signout.isSpecialAttention,
        },
      });

      // data for print
      return Object.assign(
        { signin: state.signIn },
        { timeout: state.timeOut },
        { signout: state.signOut },
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setLoading', { label: 'dataById', value: false });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
