/* eslint-disable */

const terbilang = a => {
  var c = ' Satu Dua Tiga Empat Lima Enam Tujuh Delapan Sembilan Sepuluh Sebelas'.split(
    ' ',
  );
  if (12 > a) var b = c[a];
  else
    20 > a
      ? (b = c[a - 10] + ' Belas')
      : 100 > a
      ? ((b = parseInt(String(a / 10).substr(0, 1))),
        (b = c[b] + ' Puluh ' + c[a % 10]))
      : 200 > a
      ? (b = 'Seratus ' + terbilang(a - 100))
      : 1e3 > a
      ? ((b = parseInt(String(a / 100).substr(0, 1))),
        (b = c[b] + ' Ratus ' + terbilang(a % 100)))
      : 2e3 > a
      ? (b = 'Seribu ' + terbilang(a - 1e3))
      : 1e4 > a
      ? ((b = parseInt(String(a / 1e3).substr(0, 1))),
        (b = c[b] + ' Ribu ' + terbilang(a % 1e3)))
      : 1e5 > a
      ? ((b = parseInt(String(a / 100).substr(0, 2))),
        (a %= 1e3),
        (b = terbilang(b) + ' Ribu ' + terbilang(a)))
      : 1e6 > a
      ? ((b = parseInt(String(a / 1e3).substr(0, 3))),
        (a %= 1e3),
        (b = terbilang(b) + ' Ribu ' + terbilang(a)))
      : 1e8 > a
      ? ((b = parseInt(String(a / 1e6).substr(0, 4))),
        (a %= 1e6),
        (b = terbilang(b) + ' Juta ' + terbilang(a)))
      : 1e9 > a
      ? ((b = parseInt(String(a / 1e6).substr(0, 4))),
        (a %= 1e6),
        (b = terbilang(b) + ' Juta ' + terbilang(a)))
      : 1e10 > a
      ? ((b = parseInt(String(a / 1e9).substr(0, 1))),
        (a %= 1e9),
        (b = terbilang(b) + ' Milyar ' + terbilang(a)))
      : 1e11 > a
      ? ((b = parseInt(String(a / 1e9).substr(0, 2))),
        (a %= 1e9),
        (b = terbilang(b) + ' Milyar ' + terbilang(a)))
      : 1e12 > a
      ? ((b = parseInt(String(a / 1e9).substr(0, 3))),
        (a %= 1e9),
        (b = terbilang(b) + ' Milyar ' + terbilang(a)))
      : 1e13 > a
      ? ((b = parseInt(String(a / 1e10).substr(0, 1))),
        (a %= 1e10),
        (b = terbilang(b) + ' Triliun ' + terbilang(a)))
      : 1e14 > a
      ? ((b = parseInt(String(a / 1e12).substr(0, 2))),
        (a %= 1e12),
        (b = terbilang(b) + ' Triliun ' + terbilang(a)))
      : 1e15 > a
      ? ((b = parseInt(String(a / 1e12).substr(0, 3))),
        (a %= 1e12),
        (b = terbilang(b) + ' Triliun ' + terbilang(a)))
      : 1e16 > a &&
        ((b = parseInt(String(a / 1e15).substr(0, 1))),
        (a %= 1e15),
        (b = terbilang(b) + ' Kuadriliun ' + terbilang(a)));
  a = b.split(' ');
  c = [];
  for (b = 0; b < a.length; b++) '' != a[b] && c.push(a[b]);
  return c.join(' ');
};

const limitText = (text, limit) => {
  if (text.length <= limit) {
    return text;
  } else {
    return text.slice(0, limit) + '...';
  }
};

const capitalizeText = sentence => {
  if (!sentence) return null;
  let words = sentence.split(' ');
  let capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return capitalizedWords.join(' ');
};

const scrollToEmptyInput = () => {
  const el = document.querySelector('.v-messages.error--text:first-of-type');
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }
};

const formatAddress = address => {
  const addressData = [address.text];
  if (address.sub_district) {
    addressData.push(address.sub_district);
  }
  if (address.district) {
    addressData.push(address.district);
  }
  if (address.city) {
    addressData.push(address.city);
  }
  if (address.province) {
    addressData.push(address.province);
  }
  return addressData.join(', ');
};

module.exports = {
  terbilang,
  limitText,
  capitalizeText,
  scrollToEmptyInput,
  formatAddress,
};
